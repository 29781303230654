import React, { useEffect, useState } from "react";
import { Button, Grid, Divider } from "@mui/material";
import {
    ACTIVE_MENU_PATH, ADMIN_UI_USER_ROLE, ADOPS_UI_USER_ROLE,
    AMG_LOGO, BUISNESS_UI_USER_ROLE,
    ONB_UI_USER_ROLE,
    USER_DATA_KEY, USER_ROLES_PERMISSIONS
} from "../../Constant";
import "./index.css"
import {getLocalStorageData} from "../../utils/localStorageHelper";
import BasicPopover from "./LogoutPopover";
import {isEmpty} from "../../utils/helper";

function Header(props) {
    const [pathActive, setPathActive] = useState("")
    const [nameInitial, setNameInitial] = useState("")
    const [userData,setUserData]=useState({})
    const [availableMenu, setAvailableMenu] = useState([])

    useEffect(() => {
        const getPathName = window.location.pathname
        const getActivePathKey = ACTIVE_MENU_PATH.find((item) => getPathName.includes(item.path) && item)
        const user_data = getLocalStorageData(USER_DATA_KEY,true);
        const getUserRoles = user_data?.user_roles || []

        if (getActivePathKey?.key) {
            setPathActive(getActivePathKey.key)
        }

        if(!isEmpty(user_data) && !isEmpty(getUserRoles)) {
            getInitials(user_data);
            if(getUserRoles.includes(ADMIN_UI_USER_ROLE)){
                setAvailableMenu(USER_ROLES_PERMISSIONS[ADMIN_UI_USER_ROLE])
            }

            if(getUserRoles.includes(BUISNESS_UI_USER_ROLE)){
                setAvailableMenu(USER_ROLES_PERMISSIONS[BUISNESS_UI_USER_ROLE])
            }

            if(getUserRoles.includes(ONB_UI_USER_ROLE)){
                setAvailableMenu(USER_ROLES_PERMISSIONS[ONB_UI_USER_ROLE])
            }

            if(getUserRoles.includes(ADOPS_UI_USER_ROLE)){
                setAvailableMenu(USER_ROLES_PERMISSIONS[ADOPS_UI_USER_ROLE])
            }
        }
    }, [])

    const getInitials = (user_data)=>{
        if (user_data?.user_name) {
            const nameParts = user_data.user_name.split(' ');
            let initials = '';
            nameParts.forEach(part => {
                initials += part.charAt(0);
            });
            setNameInitial(initials);
            setUserData(user_data)
        }
    }


    return (
        <Grid className="amg-content-header" container>
            <Grid container className="amg-header">
                <Grid item xs={12} sm={11} lg={10} xl={9} className="amg-menu-header-item">
                    <Grid container>
                        <Grid item xs={2} sm={2} lg={1} className="icon-grid">
                            <img src={AMG_LOGO} alt="amagi" className="amg-header-logo" />
                        </Grid>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', width: '1px', marginLeft: '18px' }} />
                        <Grid item xs={10} sm={9} lg={10} className="amg-header-menu-items">
                            {availableMenu.map((nav) => (
                                <Button variant="text" size="small" key={nav.key} href={nav.link} className={nav.key === pathActive ? 'active' : ''} >
                                {nav.label}
                                </Button>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} sm={1} lg={1} className="amg-header-user-info">
                    <BasicPopover Initial={nameInitial} userData={userData} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Header
