import {
    JWT_TOKEN_KEY,
    ONGOING_API_CALL_KEY,
    SELECTED_CHANNEL_KEY,
    SELECTED_PLATFORM_KEY,
    SELECTED_ROUTER_KEY,
    USER_DATA_KEY
} from "../Constant";
import useCookieStorageHelper from "./cookieStorageHelper";

export const getLocalStorageData = (key = "", parse = false) => {
    const getLocalData = localStorage.getItem(key)
    if(parse){
        return JSON.parse(getLocalData)
    }

    return  getLocalData
}

export const setLocalStorageData = (key = "", parse = false, data = {}) => {
    let setLocalData = parse ? JSON.stringify(data) : data
    localStorage.setItem(key, setLocalData)
    return true
}

export const removeLocalStorageData = (key="") => {
    localStorage.removeItem(key)

    return true
}

export const logOutUser = () => {
    removeLocalStorageData(ONGOING_API_CALL_KEY)
    removeLocalStorageData(SELECTED_CHANNEL_KEY)
    removeLocalStorageData(SELECTED_PLATFORM_KEY)
    removeLocalStorageData(SELECTED_ROUTER_KEY)
    removeLocalStorageData(USER_DATA_KEY)
    useCookieStorageHelper.removeItemCookie(JWT_TOKEN_KEY)
    window.location.href = "/";

}