import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import AdsPlusPageHeader from "../../Components/AdsPlusPageHeader";
import AdsPlusTopQuickView from "../../Components/AdsPlusTopQuickView";
import {
    ADS_PLUS_DASHBOARD_FILTER_CONFIG_DEMAND,
    ADS_PLUS_DASHBOARD_FILTER_CONFIG_SUPPLY, DATA_IN_LOADING_STATE,
    DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE,
    DEMAND_HEALTH_TRACKER_PATH,
    ERROR_NOTIFICATION_TYPE, GETTING_THE_DATA, NO_DEMAND_HEALTH_TRACKER_DATA, NO_DEMAND_PARTNER_DATA,
    NO_SUPPLY_HEALTH_TRACKER_DATA,
    NO_SUPPLY_PARTNER_DATA, SUCCESS_NOTIFICATION_TYPE,
    SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE,
    SUPPLY_HEALTH_TRACKER_PATH, TOP_20_DEMAND_PARTNER,
    TOP_20_SUPPLY_PARTNER
} from "../../Constant";
import {getCurrentDateWithMonthYear, getLast6Months, groupPartners, isEmpty} from "../../utils/helper";
import {useNavigate} from "react-router-dom";
import Notification from "../../Components/Notification";
import getAllSupplyPartner from "../../api/getAllSupplyPartner";
import getSupplyHealthTrackerAPI from "../../api/getSupplyHealthTrackerData";
import getAllDemandPartner from "../../api/getAllDemandPartner";
import getDemandHealthTrackerAPI from "../../api/getDemandHealthTrackerData";
import AdsDemandHealthTabView from "../../Components/AdsDemandHealthTabView";
import AdsPlusSupplyHealthSelectView from "../../Components/AdsPlusSupplyHealthSelectView";

import "./index.css"
import AdsPlusDemandGoalTracking from "../../Components/AdsPlusDemandGoalTracking";
import useWebStorageHelper from "../../utils/sessionStorageHelper";


function AdsPlusDashboard(props){
    const [allSupplyPartnerData, setAllSupplyPartnerData] = useState([])
    const [allDemandPartnerData, setAllDemandPartnerData] = useState([])
    const [supplyPartnerOrder, setSupplyPartnerOrder] = useState([])
    const [demandPartnerOrder, setDemandPartnerOrder] = useState([])
    const [loading, setLoading] = useState(true);
    const [filterConfig, setFilterConfig] = useState({});
    const [supplyHealthTracker, setSupplyHealthTracker] = useState({});
    const [demandHealthTracker, setDemandHealthTracker] = useState({});
    const [dashboardType, setDashboardType] = useState("");
    const [errorType, setErrorType] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const [isDataAvailable, setIsDataAvailable] = useState(true);
    const [viewInterval, setViewInterval] = useState(2);
    const [viewType, setViewType] = useState('monthly'); // State to manage the view type
    const [quarterlyDateRange, setQuarterlyDateRange] = useState({
        gte: "",
        lte: ""
    })
    const [currentSelectedQuarter, setCurrentSelectedQuarter] = useState('');

    const handleViewIntervalChange = (newInterval) => {
        setViewInterval(newInterval);
    };
    const handleViewTypeChange = (newViewType) => {
        if (newViewType !== viewType) {
            setViewType(newViewType);
            if(newViewType === "monthly"){
                setQuarterlyDateRange({
                    gte: "",
                    lte: ""
                })
                setCurrentSelectedQuarter('');
            }
        }
    };

    const navigate = useNavigate()

    useEffect(() => {
        setDashboardType(props.type)
        setLoading(true)
        setErrorMsg(GETTING_THE_DATA)
        setErrorType(SUCCESS_NOTIFICATION_TYPE)
        setShowNotification(true)
        getLoadData(props.type, viewType)

        useWebStorageHelper.removeOnbFile();
    }, [props?.type])

    useEffect(() => {
        if (props?.type) {
            setDashboardType(props.type);
            getLoadData(props.type, viewType);
        }
    }, [props?.type]);

    const getLoadData = async (dashboardType, viewType) => {
        const getLastMonthValue = getLast6Months()
        const getCurrentDate = getCurrentDateWithMonthYear()

        if(dashboardType === SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE){
            try {
                const getAdsDefaultConfig = ADS_PLUS_DASHBOARD_FILTER_CONFIG_SUPPLY
                const getSupplyPartner = await getAllSupplyPartner()
                if(!isEmpty(getSupplyPartner)){
                    getAdsDefaultConfig.supply_partner.in = TOP_20_SUPPLY_PARTNER
                    getSupplyPartner.map((data) => {
                        if(TOP_20_SUPPLY_PARTNER.indexOf(data?.supply_partner_id) < 0){
                            getAdsDefaultConfig.supply_partner.in.push(data.supply_partner_id)
                        }
                        return true
                    })
                    getAdsDefaultConfig.inventory_type = "all"
                    getAdsDefaultConfig.date.lte = viewType === "monthly" ? getCurrentDate : quarterlyDateRange.lte;
                    getAdsDefaultConfig.date.gte = viewType === "monthly" ? getLastMonthValue[0].value : quarterlyDateRange.gte;
                    const getGroupedSupplyPartner = groupPartners(getSupplyPartner, TOP_20_SUPPLY_PARTNER, "supply_partner_id")
                    const getSupplyHealthData =  await getSupplyHealthTrackerAPI(getAdsDefaultConfig)
                    if(getSupplyHealthData?.daily?.length > 0 && getSupplyHealthData?.metrics){
                        setAllSupplyPartnerData(getGroupedSupplyPartner)
                        setFilterConfig(getAdsDefaultConfig)
                        setSupplyHealthTracker(getSupplyHealthData)
                        setSupplyPartnerOrder(getGroupedSupplyPartner)
                        setAllDemandPartnerData([])
                        setDemandHealthTracker({})
                        setIsDataAvailable(true)
                        setLoading(false)
                    }else {
                        setErrorMsg(NO_SUPPLY_HEALTH_TRACKER_DATA)
                        setErrorType(ERROR_NOTIFICATION_TYPE)
                        setShowNotification(true)
                        setIsDataAvailable(false)
                        setSupplyHealthTracker({})
                        setFilterConfig(getAdsDefaultConfig)
                        setAllSupplyPartnerData(getGroupedSupplyPartner)
                        setSupplyPartnerOrder(getGroupedSupplyPartner)
                        setLoading(false)
                    }
                }else {
                    setErrorMsg(NO_SUPPLY_PARTNER_DATA)
                    setErrorType(ERROR_NOTIFICATION_TYPE)
                    setShowNotification(true)
                    setFilterConfig(getAdsDefaultConfig)
                }
            }catch (error){
                setErrorMsg(DATA_IN_LOADING_STATE)
                setErrorType(ERROR_NOTIFICATION_TYPE)
                setShowNotification(true)
                setFilterConfig(ADS_PLUS_DASHBOARD_FILTER_CONFIG_SUPPLY)
            }
        }else if(dashboardType === DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE){
            try {
                const getAdsDefaultConfig = ADS_PLUS_DASHBOARD_FILTER_CONFIG_DEMAND
                const getDemandPartner = await getAllDemandPartner()

                if(!isEmpty(getDemandPartner)){
                    getAdsDefaultConfig.demand_partner_id.in = TOP_20_DEMAND_PARTNER
                    getDemandPartner.map((data) => {
                        if(TOP_20_DEMAND_PARTNER.indexOf(data?.demand_partner_id) < 0){
                            getAdsDefaultConfig.demand_partner_id.in.push(data?.demand_partner_id)
                        }
                        return true
                    })
                    getAdsDefaultConfig.date.lte = viewType === "monthly" ? getCurrentDate : quarterlyDateRange.lte;
                    getAdsDefaultConfig.date.gte = viewType === "monthly" ? getLastMonthValue[0].value : quarterlyDateRange.gte;
                    const getGroupedDemandPartner = groupPartners(getDemandPartner, TOP_20_DEMAND_PARTNER, "demand_partner_id")
                    const getDemandHealthData =  await getDemandHealthTrackerAPI(getAdsDefaultConfig)

                    if(getDemandHealthData?.daily?.length > 0 && getDemandHealthData?.metrics){
                        setAllDemandPartnerData(getGroupedDemandPartner)
                        setFilterConfig(getAdsDefaultConfig)
                        setDemandHealthTracker(getDemandHealthData)
                        setDemandPartnerOrder(getGroupedDemandPartner)
                        setAllSupplyPartnerData({})
                        setSupplyHealthTracker({})
                        setIsDataAvailable(true)
                        setLoading(false)
                    }else {
                        setErrorMsg(NO_DEMAND_HEALTH_TRACKER_DATA)
                        setErrorType(ERROR_NOTIFICATION_TYPE)
                        setShowNotification(true)
                        setIsDataAvailable(false)
                        setDemandHealthTracker({})
                        setFilterConfig(getAdsDefaultConfig)
                        setDemandPartnerOrder(getGroupedDemandPartner)
                        setAllDemandPartnerData(getGroupedDemandPartner)
                        setLoading(false)
                    }
                }else {
                    setErrorMsg(NO_DEMAND_PARTNER_DATA)
                    setErrorType(ERROR_NOTIFICATION_TYPE)
                    setShowNotification(true)
                    setFilterConfig(getAdsDefaultConfig)
                }
            }catch (error){
                setErrorMsg(DATA_IN_LOADING_STATE)
                setErrorType(ERROR_NOTIFICATION_TYPE)
                setShowNotification(true)
                setFilterConfig(ADS_PLUS_DASHBOARD_FILTER_CONFIG_DEMAND)
            }
        }
    }

    const changeViewDashboardType = (type) => {
        setDashboardType(type);
        // Preserve viewType without resetting
        if (type === SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE) {
            navigate(SUPPLY_HEALTH_TRACKER_PATH);
        } else if (type === DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE) {
            navigate(DEMAND_HEALTH_TRACKER_PATH);
        }
    };


    const onFilterChange = async (config) => {
        setLoading(true)
        try{
            if(!isEmpty(config) && dashboardType === SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE){
                const getStateSupplyPartnerOrder = Object.assign([], allSupplyPartnerData)
                const getSupplyHealthData =  await getSupplyHealthTrackerAPI(config)
                if(getSupplyHealthData?.daily?.length > 0 ){
                    const getSelectedSupplyPartner = config?.supply_partner?.in || []
                    const currentPartnerOrder = getSelectedSupplyPartner.map((id) => {
                        return getStateSupplyPartnerOrder.find((partner) => partner?.supply_partner_id === id && partner)
                    })
                    setFilterConfig(config)
                    setSupplyHealthTracker(getSupplyHealthData)
                    setLoading(false)
                    setSupplyPartnerOrder(currentPartnerOrder)
                    setIsDataAvailable(true)
                }else {
                    setErrorMsg(NO_SUPPLY_HEALTH_TRACKER_DATA)
                    setErrorType(ERROR_NOTIFICATION_TYPE)
                    setSupplyHealthTracker({})
                    setShowNotification(true)
                    setIsDataAvailable(false)
                    setLoading(false)
                }
            }else if(!isEmpty(config) && dashboardType === DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE){
                const getStateDemandPartnerOrder = Object.assign([], allDemandPartnerData)
                const getDemandHealthData =  await getDemandHealthTrackerAPI(config)

                if(getDemandHealthData?.daily?.length > 0 ){
                    const getSelectedDemandPartner = config?.demand_partner_id?.in || []
                    const currentPartnerOrder = getSelectedDemandPartner.map((id) => {
                        return getStateDemandPartnerOrder.find((partner) => partner?.demand_partner_id === id && partner)
                    })
                    setFilterConfig(config)
                    setDemandHealthTracker(getDemandHealthData)
                    setLoading(false)
                    setDemandPartnerOrder(currentPartnerOrder)
                    setIsDataAvailable(true)
                }else {
                    setErrorMsg(NO_DEMAND_HEALTH_TRACKER_DATA)
                    setErrorType(ERROR_NOTIFICATION_TYPE)
                    setDemandHealthTracker({})
                    setShowNotification(true)
                    setIsDataAvailable(false)
                    setLoading(false)
                }
            }
        }catch (err){
            setErrorMsg("NO_DATA_FOUND_MESSAGE")
            setErrorType(ERROR_NOTIFICATION_TYPE)
            setShowNotification(true)
        }
    }

    const onCloseNotification = () => {
        setErrorMsg("");
        setShowNotification(false);
    }

    return(
        <Grid container>
            <Grid item xs={12} className="top-dashboard-filter">
                <AdsPlusPageHeader
                    supplyPartnerData={allSupplyPartnerData}
                    onFilterChange={onFilterChange}
                    changeViewDashboardType={changeViewDashboardType}
                    dashboardType={dashboardType}
                    filterConfig={filterConfig}
                    onViewIntervalChange={handleViewIntervalChange}
                    demandPartnerData={allDemandPartnerData}
                    loading={loading}
                    viewType={viewType}
                    setViewType={handleViewTypeChange}
                    setQuarterlyDateRange={setQuarterlyDateRange}
                    currentSelectedQuarter={currentSelectedQuarter}
                    setCurrentSelectedQuarter={setCurrentSelectedQuarter}
                />
            </Grid>
            {dashboardType === DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE &&(
                <Grid item xs={12} className="amg-body-top-goal-tracking">
                    <AdsPlusDemandGoalTracking
                        loading={loading}
                    />
                </Grid>
            )}
            <Grid item xs={12}  className="top-quick-view">
                <AdsPlusTopQuickView
                    loading={loading}
                    supplyHealthTracker={supplyHealthTracker}
                    demandHealthTracker={demandHealthTracker}
                    dashboardType={dashboardType}
                    isDataAvailable={isDataAvailable}
                />
            </Grid>
            <Grid item xs={12} className="content-bar-table-view">
                {dashboardType === SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE && (
                    <AdsPlusSupplyHealthSelectView
                        loading={loading}
                        supplyHealthTracker={supplyHealthTracker}
                        supplyPartnerData={allSupplyPartnerData}
                        supplyPartnerOrder={supplyPartnerOrder}
                        isDataAvailable={isDataAvailable}
                        viewInterval={viewInterval}
                        viewType={viewType}
                        onViewTypeChange={handleViewTypeChange}
                    />
                )
                }
                {dashboardType === DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE && (
                    <AdsDemandHealthTabView
                        loading={loading}
                        demandHealthTracker={demandHealthTracker}
                        dashboardType={dashboardType}
                        demandPartnerOrder={demandPartnerOrder}
                        isDataAvailable={isDataAvailable}
                        viewInterval={viewInterval}
                        viewType={viewType}
                        onViewTypeChange={handleViewTypeChange}
                    />
                )}
            </Grid>
            <Notification
                message={errorMsg}
                type={errorType}
                open={showNotification}
                handleClose={onCloseNotification}
            />
        </Grid>
    )
}

export default AdsPlusDashboard