import "./index.css"
import {Grid, Table, TableCell, TableBody, TableHead, TableRow, Tooltip, Typography,Popover} from "@mui/material"
import React, {useEffect, useState} from "react"
import LayoutColumnIcon from "../../../assests/icons/layoutColumnIcon"
import { reduceString, formatNumber } from "../../../utils/helper";
import { LoadingScreen } from "../../CommonScreens/LoadingScreen"
import { EmptyScreen } from "../../CommonScreens/EmptyScreen"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DownIcon from "../../../assests/icons/downIcon";
import {SELECT1_OPTION_STR, SORT_STR_LIST, TADR_HTL, TADR_LTH, TI_HTL,TI_LTH} from "../../../Constant";

const ReportPreviewTable = (props) => {
    const [sortBy,setSortBy]=useState(TADR_HTL)
    const [openPopover, setOpenPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        setSortedData(sortTableData(props.tableData, sortBy));
    }, [props.tableData, sortBy]);

    const handleClickSortBy = (event) => {
        setOpenPopover(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(false);
        setAnchorEl(null);
    };
    const setSortByQuery =(newQuery) =>{
        setSortBy(newQuery)
        handleClosePopover()
    }

    const sortTableData = (data, sortBy) => {
        if (!data || data.length === 0) return [];
        const sorted = [...data];
        switch (sortBy) {
            case TADR_HTL:
                sorted.sort((a, b) => b.total_ad_request - a.total_ad_request);
                break;
            case TADR_LTH:
                sorted.sort((a, b) => a.total_ad_request - b.total_ad_request);
                break;
            case TI_HTL:
                sorted.sort((a, b) => b.total_impression - a.total_impression);
                break;
            case TI_LTH:
                sorted.sort((a, b) => a.total_impression - b.total_impression);
                break;
            default:
                break;
        }
        return sorted;
    };

    return (
        <React.Fragment>
            <Grid item xs={12} className="report-table-preview-grid">
                <Grid container className="report-table-preview-heading">
                    <span className="report-table-preview-heading-span">Report preview</span>
                    <span className="report-table-preview-note-span">Preview upto 100 results. To view the full report, please “Save & generate” to download it</span>
                </Grid>
                <Grid item xs={12} className="table-frame">
                    <Grid item xs={12} className="table-toolbar">
                        <span className="report-table-preview-note-span">{props.tableLength} results</span>
                        <div className="sort-table-div" onClick={handleClickSortBy}>
                            <span className="sort-by-span">Sort by:</span>
                                <Typography className="sort-name-typography">
                                    {sortBy}
                                </Typography>
                            <span className="down-icon-span-preview-table"><DownIcon /></span>
                        </div>
                        <Popover
                            className="popover-sort"
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <Grid className="sort-by-list">
                                {SORT_STR_LIST.map((option) => (
                                    <div
                                        className="options-popover"
                                        key={option}
                                        onClick={()=>{setSortByQuery(option)}}
                                    >
                                        {option}
                                    </div>
                                ))}
                            </Grid>
                        </Popover>

                    </Grid>
                    <Table className="report-table sticky-column" stickyHeader >
                        <TableHead>
                            <TableRow >
                                {props?.tableHeader?.map((column) => (

                                    <TableCell
                                        key={column.id}
                                        style={{
                                            whiteSpace: 'nowrap',
                                            ...(column.id === 'supply_tag_id' && {
                                                position: 'sticky',

                                                left: 0,
                                                zIndex: 999

                                            })
                                        }}
                                    >
                                        {column.title}
                                    </TableCell>

                                ))}
                                <TableCell style={{ position: 'sticky', right: 0 }} >
                                    <div onClick={() => props.openColumnSidebar()}>
                                        <LayoutColumnIcon />
                                    </div>

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData?.length > 0 ?
                                sortedData?.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {props?.tableHeader?.map((column, colIndex) => (
                                            <Tooltip className="tooltip-arrow" title={row[column.id]} arrow >
                                                <TableCell
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        ...(column.id === "supply_tag_id" && {
                                                            position: 'sticky',
                                                            left: 0
                                                        })
                                                    }}
                                                    key={`${rowIndex}-${colIndex}`}
                                                >
                                                    {column.id === "total_ad_request" || column.id === "total_impression"
                                                        ? formatNumber(row[column.id])
                                                        : reduceString(row[column.id])}
                                                </TableCell>
                                            </Tooltip>
                                        ))}
                                    </TableRow>


                                )) : (!props?.isLoading) && (
                                    <TableRow >

                                        <TableCell colspan={13}> <EmptyScreen message="No results found" /> </TableCell>

                                    </TableRow>
                                )
                            }
                            {props?.isLoading && (
                                <LoadingScreen rowsNumber={18} />
                            )}
                        </TableBody>
                    </Table>

                </Grid>
            </Grid>

        </React.Fragment>

    )
}

export default ReportPreviewTable