import axios from "axios";

import { REPORT_DOWNLOAD_URI } from "../Constant";

export const downloadReport = async (dataSource, requestData) => {
    let url = ``;

    if(dataSource === "SS Core"){
        url = `${REPORT_DOWNLOAD_URI}/ss_core_report/download`;
    }else if(dataSource === "SS Ads+"){
        url = `${REPORT_DOWNLOAD_URI}/supply_transparency_report/download`;
    }


    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };


    try {
        const response = await axios.post(url, requestData, config);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
};
