import React from 'react';
import "./index.css";
import {Grid, TableCell, TableHead, TableRow, Table, TableBody, Tooltip} from "@mui/material";
import IntegratedReportTableInsights from "../IntegratedReportTableInsights";
import {IntegratedReportContext} from "../../../Context/IntegratedReportContext";
import getIntegratedReportPreview from "../../../api/getIntegratedReportPreview";
import {LoadingScreen} from "../../CommonScreens/LoadingScreen";
import {EmptyScreen} from "../../CommonScreens/EmptyScreen";
import {CSV_FORMAT} from "../../../Constant";
import {getIntegratedReportPreviewData} from "../../../api/getIntegratedReportPreviewData";

const IntegratedReportTable = () => {

    const [selectedRow, setSelectedRow] = React.useState(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [integratedReportData, setIntegratedReportData] = React.useState(null);
    const {dataSource, date, fetchPreview, supplyPartnerID, supplyTagID, isPreviewLoading, updateIsPreviewLoading, updateFetchPreview} = React.useContext(IntegratedReportContext);

    React.useEffect(() => {
        async function fetchReportPreview() {
            if (fetchPreview) {
                updateIsPreviewLoading(true);
                let reportData = [];

                if (supplyPartnerID === "") {
                    updateIsPreviewLoading(false);
                    updateFetchPreview(false);
                    return;
                } else {
                    const where = {
                        data_source: dataSource,
                        date: date.format('YYYY-MM-DD'),
                        supply_partner_id: supplyPartnerID,
                        supply_tag_id: supplyTagID,
                    };

                    const requestData = { where };

                    try {
                        // Fetch report preview data
                        reportData = await getIntegratedReportPreviewData(requestData);

                        if (
                            reportData &&
                            typeof reportData === 'object' &&
                            reportData.core_supply_tag_name &&
                            typeof reportData.core_supply_tag_name === 'object'
                        ) {
                            const keys = Object.keys(reportData.core_supply_tag_name);

                            const rows = keys.map((key) => ({
                                core_supply_tag_name: reportData.core_supply_tag_name[key],
                                ads_supply_tag_name: reportData.ads_supply_tag_name?.[key],
                                core_total_ad_requests: reportData.core_total_ad_requests?.[key],
                                ads_total_ad_requests: reportData.ads_total_ad_requests?.[key],
                                core_total_impressions: reportData.core_total_impressions?.[key],
                                ads_total_impressions: reportData.ads_total_impressions?.[key],
                                delivery_id: reportData.delivery_id?.[key],
                                core_supply_tag_id: reportData.core_supply_tag_id?.[key],
                                ads_supply_tag_id: reportData.ads_supply_tag_id?.[key],
                            }));

                            setIntegratedReportData(rows);
                        } else {
                            setIntegratedReportData([]);
                            console.error("Data structure is not as expected.");
                        }
                    } catch (error) {
                        console.error("Error parsing or fetching report data:", error);
                    }

                    updateIsPreviewLoading(false);
                    updateFetchPreview(false);
                }
            }
        }

        fetchReportPreview();
    }, [dataSource, date, fetchPreview, supplyPartnerID, supplyTagID, updateFetchPreview, updateIsPreviewLoading]);



    const handleRowClick = (row) => {
        setSelectedRow(row);
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
    };

    return (
        <React.Fragment>
            <Grid item xs={12} className="report-table-preview-grid">
                <Grid container className="report-table-preview-heading">
                    <span className="report-table-preview-heading-span">Report preview</span>
                    <span className="report-table-preview-note-span">Preview upto 50 results. To view the full report, please “Save & generate” to download it</span>
                </Grid>
                {!drawerOpen && (
                    <Grid item xs={12} className="table-frame">
                        {/*this commented code is required*/}
                    {/*<Grid item xs={12} className="table-toolbar">*/}
                    {/*    <span className="report-table-preview-note-span">*/}
                    {/*        {isPreviewLoading ? <Skeleton animation="wave" className="skleton-loading-screen" width="5%" height={20} />*/}
                    {/*            :*/}
                    {/*            integratedReportData && integratedReportData.length*/}
                    {/*         } SS-core supply tags*/}
                    {/*    </span>*/}
                    {/*</Grid>*/}
                    <Table className="report-table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className="integrated-table-heading"> SS-CORE SUPPLY TAG </TableCell>
                                <TableCell className="integrated-table-heading"> SS-ADS+ SUPPLY TAG </TableCell>
                                <TableCell className="integrated-table-heading"> SS-CORE AD REQUESTS </TableCell>
                                <TableCell className="integrated-table-heading"> SS-ADS+ AD REQUESTS </TableCell>
                                <TableCell className="integrated-table-heading"> SS-CORE IMPRESSIONS </TableCell>
                                <TableCell className="integrated-table-heading"> SS-ADS+ IMPRESSIONS </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isPreviewLoading && (<LoadingScreen rowsNumber={6}/>)}
                            {!isPreviewLoading && (integratedReportData && integratedReportData.length > 0)
                                ?
                                 integratedReportData.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        hover
                                        onClick={() => handleRowClick(row)}
                                        className="integrated-row-hover"
                                        sx={{
                                            "& .MuiTableRow-root:hover": {
                                                backgroundColor: "red"
                                            }
                                        }}
                                    >
                                        <Tooltip className="tooltip-arrow" title={row.core_supply_tag_name} arrow >
                                             <TableCell className="integrated-tableCell supply-name-width">{row.core_supply_tag_name}</TableCell>
                                        </Tooltip>
                                        <Tooltip className="tooltip-arrow" title={row.ads_supply_tag_name} arrow >
                                            <TableCell className="integrated-tableCell supply-name-width">{row.ads_supply_tag_name}</TableCell>
                                        </Tooltip>
                                        <TableCell className="integrated-tableCell supply-values-width"> {row.core_total_ad_requests} </TableCell>
                                        <TableCell sclassName="integrated-tableCell supply-values-width"> {row.ads_total_ad_requests} </TableCell>
                                        <TableCell className="integrated-tableCell supply-values-width"> {row.core_total_impressions} </TableCell>
                                        <TableCell className="integrated-tableCell supply-values-width"> {row.ads_total_impressions} </TableCell>
                                    </TableRow>
                                ))
                                :
                                (!isPreviewLoading &&
                                <TableRow>
                                    <TableCell colspan={13}> <EmptyScreen message="No results found"/> </TableCell>
                                </TableRow>
                                )}

                        </TableBody>
                    </Table>
                </Grid>
                )}

                {drawerOpen && (
                    <IntegratedReportTableInsights drawerOpen={drawerOpen} selectedRow={selectedRow} handleCloseDrawer={handleCloseDrawer}/>
                ) }
            </Grid>
        </React.Fragment>
    )
}

export default IntegratedReportTable
