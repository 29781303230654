import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getSupplyTransparencyReport = async (data_source, queryWhere, take) => {
    const whereClause = Object.entries(queryWhere)
        .map(([key, value]) => {
            if (Object.keys(value).length === 0) {
                // If the value is an empty object, construct the key without quotes
                return `${key}: {}`;
            } else {
                // Otherwise, construct the key with the operator and value directly
                let valueString;
                if (value.hasOwnProperty('in')) {
                    // If the value has 'in' property, format it as an array with double quotes for each item
                    const items = value.in.map(item => `"${item}"`).join(', ');
                    valueString = `{ in: [${items}] }`;
                } else if (value.hasOwnProperty('notIn')) {
                    // If the value has 'notIn' property, format it as an array with double quotes for each item
                    const items = value.notIn.map(item => `"${item}"`).join(', ');
                    valueString = `{ notIn: [${items}] }`;
                } else {
                    // If not using 'in' or 'notIn' operators, handle other cases as before
                    valueString = Object.entries(value)
                        .map(([op, val]) => `${op}: ${op === 'equals' ? `"${val}"` : val}`)
                        .join(',');
                    // Wrap the valueString in curly braces
                    valueString = `{ ${valueString} }`;
                }
                return `${key}: ${valueString}`;
            }
        })
        .join(',');

    // const orderByClause = Object.entries(orderBy)
    //     .map(([field, direction]) => `${field}: ${direction}`)
    //     .join(',');

    const query = `query MyQuery {
        supply_transparency_report(
            data_source: "${data_source}"
            where: {
                ${whereClause}
            }
            take: ${take}
        ) {
            date
            supply_tag_id
            supply_tag_name
            supply_partner_id
            supply_partner_name
            stream_id
            total_ad_request
            total_impression
            app_bundle
            app_name
            coppa
            device_type_id
            dnt
            gdpr
            gdpr_consent_present
            lmt
            full_page_url
            us_privacy
            channel_name
            content_genre_list
            language
            declared_player_size
            content_rating_list
            livestream
            country
            device_id
            user_ip
            user_agent
            key_values_content_language
            key_values_ifa_type
            network
            production_quality
        }
    }`;
    const headers = adsHeader()

    return axios.post(REPORT_BFF_URI, { query }, { headers: headers })
        .then(response => response.data.data.supply_transparency_report)
        .catch(error => {
            console.error("Error fetching supply transparency report:", error);
            return null;
        });
};
