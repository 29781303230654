import React, {useState} from 'react';
import "./index.css";
import {
    Grid,
    Select,
    Typography,
    MenuItem,
    Button,
    InputAdornment,
    TextField,
    ListItemIcon, ListItemText, Checkbox, Skeleton
} from "@mui/material";
import AddIcon from "../../../assests/icons/addIcon";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "../../../assests/icons/deleteIcon";
import SearchIcon from "../../../assests/icons/searchIcon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SuccessSaveIcon from "../../../assests/icons/successSaveIcon";
import getAllSupplyPartnersBySourceAndDate from "../../../api/getAllSupplyPartnersBySourceAndDate";
import {IntegratedReportContext} from "../../../Context/IntegratedReportContext";
import getAllSupplyTagsBySourceAndDate from "../../../api/getAllSupplyTagsBySourceAndDate";
import { FixedSizeList as List } from 'react-window';
import {SelectLoadingOption} from "../../CommonScreens/SelectLoadingOption";
import {MultiSelectLoadingOption} from "../../CommonScreens/MultiSelectLoadingOption";
import Chip from "@mui/material/Chip";
import {getLocalStorageData} from "../../../utils/localStorageHelper";
import {
    CSV_FORMAT, NO_CRITERIA_SELECTED, NO_DATA_SOURCE_SELECTED, NO_DATE_SELECTED,
    NO_REPORT_NAME_ENTERED, REPORT_DOWNLOAD_SUCCESS,
    REPORT_LANDING_PAGE, SUCCESS_NOTIFICATION_TYPE,
    USER_DATA_KEY,
    WARNING_NOTIFICATION_TYPE
} from "../../../Constant";
import {downloadSTRIntegratedReport} from "../../../api/downloadSTRIntegratedReport";
import {useNavigate} from "react-router-dom";
import {isEmpty} from "../../../utils/helper";
import Notification from "../../Notification";

const IntegratedReportCriteria = () => {

    const navigation = useNavigate();
    const [originalSupplyPartnerOptions, setOriginalSupplyPartnerOptions] = React.useState([]);
    const [originalTagOptions, setOriginalTagOptions] = React.useState([]);
    const [filteredSupplyPartnerOptions, setFilteredSupplyPartnerOptions] = React.useState([]);
    const [filteredTagOptions, setFilteredTagOptions] = React.useState([]);
    const [isPartnerLoading, setIsPartnerLoading] = React.useState(false);
    const [isTagLoading, setIsTagLoading] = React.useState(false);
    const [isReportDownloading, setIsReportDownloading] = React.useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState("")
    const [notificationMessage, setNotificationMessage] = useState("")
    const [criteriaList, setCriteriaList] = React.useState([
        {
            index: 0,
            criterion: "Supply Partner",
            operator: "is",
            value: [],
        },
    ]);

    const {
        date,
        dataSource,
        initialLoad,
        reportName,
        isPreviewLoading,
        updateFetchPreview,
        updateSupplyPartnerID,
        updateSupplyTagID,
        updateInitialLoad } = React.useContext(IntegratedReportContext);

    React.useEffect(() => {
        async function getAllSupplyPartnerAndTagIntegrated() {
            const selectedDate = date.format('YYYY-MM-DD');
            setIsPartnerLoading(true);
            const allSupplyPartners = await getAllSupplyPartnersBySourceAndDate(dataSource, selectedDate);
            setOriginalSupplyPartnerOptions(allSupplyPartners);
            setIsPartnerLoading(false);

            if(initialLoad){
                const firstSupplyPartnerId = allSupplyPartners && allSupplyPartners.length > 0 ? allSupplyPartners[0] : "";
                updateSupplyPartnerID(firstSupplyPartnerId?.supply_partner_id.toString());
                
                setCriteriaList((prev) => {
                    const updatedList = [...prev];
                    if(updatedList.length > 0){
                        updatedList[0] = {
                            ...updatedList[0],
                            value: firstSupplyPartnerId
                        };
                    }
                    return updatedList;
                });
                updateFetchPreview(true);
                updateInitialLoad(false);
            }

            setIsTagLoading(true);
            const allSupplyTags = await getAllSupplyTagsBySourceAndDate(dataSource, selectedDate);
            setOriginalTagOptions(allSupplyTags);
            setIsTagLoading(false);
        }
        getAllSupplyPartnerAndTagIntegrated();

    }, [date, dataSource]);

    const handleClose = () => {
        setShowNotification(false)
        setNotificationType("")
        setNotificationMessage("")
    }

    const updatePreview = React.useCallback(() => {
        let partnerIds = '';
        let tagList = [];

        criteriaList.map((item) => {
           if(item.criterion === "Supply Partner"){
               partnerIds = item?.value?.supply_partner_id ? item?.value?.supply_partner_id : "";
           } else{
               tagList = item?.value;
           }
        })

        let tagIds = tagList.map((item) => item.supply_tag_id).join(', ');

        updateSupplyPartnerID(partnerIds.toString());
        updateSupplyTagID(tagIds);
        updateFetchPreview(true);
    }, [criteriaList, updateSupplyPartnerID, updateSupplyTagID, updateFetchPreview]);

    const handleAddCriteria = () => {
        setCriteriaList([...criteriaList, { index: criteriaList.length + 1, criterion: '', operator: 'is', value: [] }]);
    };

    const handleCriteriaChange = (index, field, value) => {
        const updatedCriteria = [...criteriaList];

        if (field === 'criterion') {
            updatedCriteria[index].criterion = value;
            updatedCriteria[index].value = [];
            setCriteriaList(updatedCriteria);
        }
        else if (field === 'value') {
            if(updatedCriteria[index]['criterion'] === "Supply Partner"){
                updatedCriteria[index].value = value;
            }else {
                updatedCriteria[index].value = value;
            }
        }

        setCriteriaList(updatedCriteria);
    };

    const handleDeleteCriteria = (index) => {
        const updatedCriteria = criteriaList.filter((_, i) => i !== index);
        setCriteriaList(updatedCriteria);
    };

    const handleCriteriaValueSearchChange = (index, value) => {
        let options = criteriaList[index].criterion === 'Supply Partner'
            ? originalSupplyPartnerOptions
            : originalTagOptions;

        const filtered = options.filter(option => {
            const searchValue = value.toLowerCase();
            if (criteriaList[index].criterion === 'Supply Partner') {
                return (
                    option.supply_partner_name.toLowerCase().includes(searchValue) ||
                    option.supply_partner_id.toString().includes(searchValue)
                );
            } else {
                return (
                    option.supply_tag_name.toLowerCase().includes(searchValue) ||
                    option.supply_tag_id.toString().includes(searchValue)
                );
            }
        })

        if(criteriaList[index].criterion === 'Supply Partner'){
            setFilteredSupplyPartnerOptions(filtered);
        } else {
            setFilteredTagOptions(filtered);
        }
    };


    const getAvailableCriteriaOptions = (index) => {
        // Get a list of already selected criteria to exclude from the available options
        const selectedCriteria = criteriaList.map(c => c.criterion);
        const isSupplyPartnerSelected = selectedCriteria.includes("Supply Partner");
        const isSupplyTagSelected = selectedCriteria.includes("Supply Tag");

        let availableOptions = [];
        if (!isSupplyPartnerSelected || (isSupplyPartnerSelected && criteriaList[index].criterion === "Supply Partner")) {
            availableOptions.push("Supply Partner");
        }
        if (!isSupplyTagSelected || (isSupplyTagSelected && criteriaList[index].criterion === "Supply Tag")) {
            availableOptions.push("Supply Tag");
        }

        return availableOptions;
    };

    const handleCancel = () => {
        setCriteriaList([
            {
                index: 0,
                criterion: "Supply Partner",
                operator: "is",
                value: [],
            },
        ]);
    }

    const handleIntegratedReportDownload = async () => {

        setIsReportDownloading(true);
        if(!reportName){
            setShowNotification(true)
            setNotificationType(WARNING_NOTIFICATION_TYPE)
            setNotificationMessage(NO_REPORT_NAME_ENTERED)
            setIsReportDownloading(false);
        }else{

            let partnerIds = '';
            let tagList = [];
            criteriaList.map((item) => {
                if(item.criterion === "Supply Partner"){
                    partnerIds = item?.value?.supply_partner_id ? item?.value?.supply_partner_id : "";
                } else{
                    tagList = item?.value;
                }
            })

            let tagIds = tagList.map((item) => item.supply_tag_id).join(', ');

            updateSupplyPartnerID(partnerIds.toString());
            updateSupplyTagID(tagIds);

            const selectedDate = date.format('YYYY-MM-DD');
            const where = {
                data_source: dataSource,
                date: selectedDate,
                supply_partner_id: partnerIds.toString(),
                supply_tag_id: tagIds

            }
            try {
                const user_data = getLocalStorageData(USER_DATA_KEY, true);
                let userEmail = user_data?.email || "";
                const requestData = {
                    where,
                    user_id: userEmail,
                    format: CSV_FORMAT,
                    name: reportName
                };
                let response = await downloadSTRIntegratedReport(requestData);
                setIsReportDownloading(false);

                //to-do need to read from status_code
                if(response && response?.message === "job submitted successfully"){
                    setShowNotification(true)
                    setNotificationType(SUCCESS_NOTIFICATION_TYPE)
                    setNotificationMessage(REPORT_DOWNLOAD_SUCCESS)
                }

                setTimeout(() => {
                    navigation(`/${REPORT_LANDING_PAGE}`);
                }, 3000);

            } catch (error) {

                console.error("Error downloading STR Integrated report:", error);

            }
        }
    };

    return (
        <React.Fragment>
            <Grid container xs={12} spacing={2} className="integrated-report-input-grid condition-textfield-grid container-margin">
                <Grid container>
                    <Grid item xs={1.5} >
                        <Typography
                            className="amg-form-label">
                            Report Criteria
                        </Typography>
                    </Grid>

                    <Grid className="criteria-input-select-grid" item xs={10.5} >
                        {criteriaList && criteriaList.map((criteria, index) => (
                            <React.Fragment key={criteria.id}>
                                <Grid container xs={12} spacing={1} className="criteria-right" alignItems="center">
                                    <Grid item xs={2} sm={2} md={2} >
                                        <Select
                                            id="Criterion"
                                            label="Criterion"
                                            variant="standard"
                                            defaultValue={index === 0 ? "Supply Partner" : ""}
                                            onChange={(e) => handleCriteriaChange(index, 'criterion', e.target.value)}
                                            className="criteria-operator-select-integrated criterion-width"
                                        >
                                            {index === 0
                                                ?
                                                    <MenuItem value="Supply Partner">Supply Partner</MenuItem>
                                                :
                                                    <MenuItem value="Supply Tag">Supply Tag</MenuItem>
                                            }
                                        </Select>
                                    </Grid>

                                    <Grid item xs={1} className="is-text">
                                        <Typography>
                                            is
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={index !== 0 ? 8.5 : 9}>
                                        {criteriaList[index].criterion === 'Supply Partner' ?
                                            <Select
                                                value={criteriaList[index]?.value || ""}
                                                renderValue={(selected) => selected.supply_partner_id ? `${selected.supply_partner_id} - ${selected.supply_partner_name}` : ""}
                                                variant="standard"
                                                onChange={(e) => {
                                                    handleCriteriaChange(index, 'value', e.target.value);
                                                }}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                className={
                                                    index === 0
                                                        ? "amg-filter-type-select integrated-valueSelect integrated-valueSelect-withoutDelete"
                                                        : "amg-filter-type-select integrated-valueSelect integrated-valueSelect-withDelete"
                                                }
                                                IconComponent={KeyboardArrowDownIcon}
                                                MenuProps={{
                                                    autoFocus: false,
                                                    classes: { paper: 'custom-menu-paper' },
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 350 // Set maximum height for dropdown
                                                        }
                                                    }
                                                }}
                                                onOpen={() => {
                                                    setFilteredSupplyPartnerOptions(originalSupplyPartnerOptions);
                                                }}
                                            >
                                                {/* Search field */}
                                                <MenuItem
                                                    key="search"
                                                    className="multi-select-item all-group"
                                                    onKeyDown={(e) => e.stopPropagation()}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        className="fixed-width-textfield integrated-valueSelect-textfield"
                                                        inputProps={{
                                                            className: 'partner-search',
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        placeholder="Search"
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            handleCriteriaValueSearchChange(index, e.target.value);
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                    />
                                                </MenuItem>

                                                {/* Render partner options */}
                                                {isPartnerLoading && ( <SelectLoadingOption count={10} width={'100%'} height={20} />)}
                                                {(originalSupplyPartnerOptions && originalSupplyPartnerOptions.length > 0) || (filteredSupplyPartnerOptions && filteredSupplyPartnerOptions.length > 0) ? (
                                                    filteredSupplyPartnerOptions.map((criteriaValue, valueIndex) => (
                                                        <MenuItem
                                                            value={criteriaValue}
                                                            key={valueIndex}
                                                            className={criteriaList[index].value?.supply_partner_name === criteriaValue.supply_partner_name ? "currentSelectedValue" : ""}
                                                        >
                                                            <ListItemText primary={`${criteriaValue.supply_partner_id} - ${criteriaValue.supply_partner_name}`} className="criteriaValueList" />
                                                            {criteriaList[index].value?.supply_partner_id === criteriaValue.supply_partner_id && (
                                                                <ListItemIcon>
                                                                    <SuccessSaveIcon />
                                                                </ListItemIcon>
                                                            )}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem disabled key="no-options">No Partners Found</MenuItem>
                                                )}
                                            </Select>

                                            :

                                            <Select
                                                multiple
                                                value={criteriaList[index].value}
                                                renderValue={(selected) => (

                                                        selected.map((item) => (
                                                            <Chip
                                                                key={item.supply_tag_id}
                                                                label={`${item.supply_tag_id} - ${item.supply_tag_name}`}
                                                                className="tag-chip"
                                                            />
                                                        ))

                                                )}
                                                variant="standard"
                                                onChange={(e) =>{
                                                    let value = e.target.value;
                                                    handleCriteriaChange(index, 'value', value);
                                                }}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                className={
                                                    index === 0
                                                        ? "amg-filter-type-select integrated-valueSelect integrated-valueSelect-withoutDelete"
                                                        : "amg-filter-type-select integrated-valueSelect integrated-valueSelect-withDelete"
                                                }
                                                IconComponent={KeyboardArrowDownIcon}
                                                MenuProps={{
                                                    autoFocus: false,
                                                    classes: { paper: 'custom-menu-paper' },
                                                }}
                                                onOpen={() => {
                                                    setFilteredTagOptions(originalTagOptions); // Reset for tags
                                                }}
                                            >
                                                {isTagLoading && (
                                                    <MultiSelectLoadingOption count={10} width={'90%'} heigth={20} checkBoxWidth={'5%'} checkBoxHeight={20} />
                                                )}
                                                {!isTagLoading && (filteredTagOptions && filteredTagOptions.length > 0) ? (
                                                    <React.Fragment>
                                                        {/* Search input */}
                                                        <MenuItem className="multi-select-item all-group" onKeyDown={(e) => e.stopPropagation()} >
                                                            <TextField
                                                                variant="outlined"
                                                                size="small"
                                                                className="fixed-width-textfield integrated-valueSelect-textfield"
                                                                inputProps={{
                                                                    className: 'partner-search',
                                                                }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <SearchIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                placeholder="Search"
                                                                onChange={(e) => {
                                                                    e.preventDefault();
                                                                    handleCriteriaValueSearchChange(index, e.target.value);
                                                                }}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }}
                                                            />
                                                        </MenuItem>
                                                        {filteredTagOptions && (
                                                            <List
                                                                height={300}  // Height of the dropdown container
                                                                itemCount={filteredTagOptions.length}
                                                                itemSize={50}
                                                                width="100%"
                                                            >
                                                                {/*TO-DO make code cleaner */}
                                                                {({ index: optionIndex, style }) => {
                                                                    const criteriaValue = filteredTagOptions[optionIndex];

                                                                    const isSelected = Array.isArray(criteriaList[index]?.value) &&
                                                                        criteriaList[index]?.value.some(
                                                                            (item) => item.supply_tag_id === criteriaValue.supply_tag_id
                                                                        );

                                                                    const handleToggleSelection = () => {
                                                                        const currentValue = criteriaList[index].value;

                                                                        let updatedList;
                                                                        if (isSelected) {
                                                                            // removing, if already selected
                                                                            updatedList = currentValue.filter(
                                                                                (item) => item.supply_tag_id !== criteriaValue.supply_tag_id
                                                                            );
                                                                        } else {
                                                                            // adding, if not selected
                                                                            updatedList = [...currentValue, criteriaValue];
                                                                        }

                                                                        // updating the value for that index
                                                                        handleCriteriaChange(index, 'value', updatedList);
                                                                    };

                                                                    return (
                                                                        <MenuItem
                                                                            key={optionIndex}
                                                                            style={style} // Style from react-window for virtualization
                                                                            onClick={handleToggleSelection} // Toggle selection on click
                                                                            className={isSelected ? "currentSelectedValue" : ""}
                                                                        >
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    // className="criterion-checkbox"
                                                                                    checked={isSelected}
                                                                                />
                                                                            </ListItemIcon>
                                                                            <ListItemText
                                                                                primary={`${criteriaValue.supply_tag_id} - ${criteriaValue.supply_tag_name}`}
                                                                                className="criteriaValueList"
                                                                            />
                                                                        </MenuItem>
                                                                    );
                                                                }}
                                                            </List>
                                                        )}
                                                    </React.Fragment>
                                                    )
                                                    :
                                                    (!isTagLoading && (
                                                        <MenuItem> No Tags Found </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        }



                                    </Grid>
                                    {index !== 0 && (
                                        <Grid item xs={0.5} >
                                            <IconButton className="integrated-delete-icon-button"
                                                        onClick={() => handleDeleteCriteria(index)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    )}
                                </Grid>
                            </React.Fragment>
                        ))}

                        <Grid item xs={12}>
                            <Button
                                className={criteriaList.length === 2 || criteriaList[0].criterion === 'Supply Tag' ? "add-criteria-button addCriteria-disabled" : "add-criteria-button"}
                                onClick={() => {
                                    handleAddCriteria();
                                }}
                                disabled={ criteriaList.length === 2 || criteriaList[0].criterion === 'Supply Tag' }
                            >
                                <AddIcon />
                                <span className="add-criteria-span">Add Criteria</span>
                            </Button>
                        </Grid>
                    </Grid>

                    {/*button group*/}
                    <Grid item xs={12} className="report-input-footer integrated-button-group">
                    <Grid item xs={12} className="report-input-footer-button-grid">
                        <Button className="report-cancel-button int-cancel-button"
                                disabled={isPreviewLoading}
                            onClick={() => handleCancel()}
                        >
                            Cancel
                        </Button>
                        <Button className="report-update-preview-button int-update-button"
                                disabled={isPreviewLoading}
                                onClick={() => updatePreview()}>
                            Update preview
                        </Button>
                        <Button className="orange-button int-save-button"
                                disabled={isPreviewLoading || isReportDownloading}
                                onClick={() => handleIntegratedReportDownload()}>
                            Save & generate
                        </Button>
                    </Grid>
                    </Grid>
                </Grid>
                <Notification
                    message={notificationMessage}
                    handleClose={handleClose}
                    type={notificationType}
                    open={showNotification}
                />
            </Grid>
        </React.Fragment>
    )
}

export default IntegratedReportCriteria
