import React, {useEffect} from 'react';
import {Badge, Grid, Skeleton, Typography, Button} from '@mui/material';
import {getEpochData, getImageName} from '../../utils/helper';
import './index.css';
import CommentsIcon from '../../assests/icons/CommentsIcon';
import EditIcon from "../../assests/icons/EditIcon";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import {
    ADMIN_ROLE, AD_OPS_USER_ROLE, EDIT_REQUEST_DETAILS_DRAFT_PAGE_LINK,
    NA,
    REQUEST_STATUS_CREATE, REQUEST_STATUS_REVIEW, REQUEST_STATUS_SUBMIT, REQUESTS_LISTING_PAGE_LINK,
    STATUS_APPROVAL_PENDING,
    STATUS_APPROVED, SUCCESS_TYPE, TS_PLATFORM_TYPE,
    REQUEST_CONFIG_SESSION_KEY, WARNING_TYPE, REQUEST_STATUS_DRAFT, STATUS_DRAFT, BUSINESS_USER_ROLE, ADMIN_UI_USER_ROLE
} from "../../Constant";
import CrossIcon from "../../assests/icons/crossIcon";
import {useNavigate} from "react-router-dom";
import approveRequestById from "../../api/approveRequestById";
import useWebStorageHelper from "../../utils/sessionStorageHelper";
import {submitRequestApi} from "../../api/submitRequestApi";
import CircularProgress from "@mui/material/CircularProgress";

function RequestDetailsHeader(props) {

    const [status, setStatus] = React.useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const navigate = useNavigate();
    const [userData, setUserData] = React.useState({});
    const [isApproving, setIsApproving] = React.useState(false);


    useEffect(() => {
        if (props?.Details?.status === REQUEST_STATUS_CREATE || props?.Details?.status === REQUEST_STATUS_REVIEW ) {
            setStatus(STATUS_APPROVAL_PENDING);
        } else if(props?.Details?.status === REQUEST_STATUS_DRAFT){
            setStatus(STATUS_DRAFT);
        } else {
            setStatus(STATUS_APPROVED);
        }
        if(props?.userData){
            setUserData(props.userData);
        }
    }, [props?.Details?.status]);

    const renderValueOrSkeleton = (value) => {
        return props.loading ? <Skeleton variant="text" animation="wave" width={100}/> : value;
    };

    const handleCommentsButtonClick = () => {
        const comments = props?.Details?.comments || [];
        props.handleCommentsClick(comments);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const handleApproveRequest = async () => {
        setOpenDialog(false);
        setIsApproving(true)
        try {
            let submitApiResponse = await submitRequestApi(props?.Details?.request_job_id)

            if(submitApiResponse?.status){
                props.shoNotifications(submitApiResponse?.msg, submitApiResponse?.type);
                let response = await approveRequestById(props?.Details?.request_job_id, REQUEST_STATUS_SUBMIT, userData.user_email);
                if (response?.request_job_id) {
                    useWebStorageHelper.setItemSessionWithExpiry("Notify", SUCCESS_TYPE, 3000);
                    navigate(REQUESTS_LISTING_PAGE_LINK);
                } else {
                    props.shoNotifications("Status update failed", WARNING_TYPE);
                }
            }else {
                props.shoNotifications(submitApiResponse?.msg, submitApiResponse?.type);
            }
        }
        catch (error) {
            props.shoNotifications("Status update failed", WARNING_TYPE);
        }
        finally {
            setIsApproving(false);
        }
    }

    const handleNavigate = () => {
        let sessionData = {
            "ts_channel_id": props?.Details?.meta?.ts_channel_id ? props.Details.meta.ts_channel_id : "",
            "ss_core_supply_router_id": props?.Details?.meta?.ss_core_supply_router_id ? props.Details.meta.ss_core_supply_router_id : "",
            "platform_amg_id": props?.Details?.meta?.platform_amg_id ? props.Details.meta.platform_amg_id : "",
            "creation_type": props?.Details?.meta?.creation_type ? props.Details.meta.creation_type : "",
            "platform_name": props?.Details?.meta?.platform_name ? props.Details.meta.platform_name : "",
            "type": props?.Details?.type ? props.Details.type : "",
            "status": props?.Details?.status ? props.Details.status : "",
            "user_email": userData.email ? userData.email : "",
        }
        useWebStorageHelper.setItemSession(REQUEST_CONFIG_SESSION_KEY, sessionData);
        navigate(EDIT_REQUEST_DETAILS_DRAFT_PAGE_LINK + props?.Details?.request_job_id);
    }

    return (
        <React.Fragment>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <div className="dialog-title">
                    <div>Approve request</div>
                    <div><CrossIcon handleCloseDialog={handleCloseDialog}/></div>
                </div>
                <div className="tab-divider-dialog"></div>
                <div className="dialog-content">
                    <div>Are you sure you want to approve this request?</div>
                </div>
                <div className="tab-divider-dialog"></div>
                {!isApproving ? (
                    <DialogActions>
                        <Button size="default" className='report-cancel-button' onClick={handleCloseDialog}>Cancel </Button>
                        <Button size="default" className="orange-button" onClick={handleApproveRequest}>Yes, approve</Button>
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <CircularProgress size={34} disableShrink />
                    </DialogActions>
                ) }
            </Dialog>
            <Grid item xs={12} className="platform-header">
                <Grid container>
                    <Grid item>
                        <div className="req-img-div">
                            {props?.headerType === 'Request' ? (
                                <Typography className="img-typography">{getImageName(props?.Details?.config?.customer_details?.channel_name)}</Typography>
                            ) : props?.Details?.platform_logo_url ? (
                                <img src={props?.Details?.platform_logo_url} alt="logo"/>
                            ) : (
                                <Typography className="img-typography">{getImageName(props?.Details?.name)}</Typography>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={11}>
                        <div>
                            <div className="header-and-buttons">
                                <div>
                                    <div className="request-head-div">Channel</div>
                                    <div className="request-head-name-div">
                                        <div>
                                            {renderValueOrSkeleton(props?.Details?.config?.customer_details?.channel_name ?  props?.Details?.config?.customer_details?.channel_name : NA)}
                                        </div>
                                        <span>
                                            <Badge
                                                className={status === STATUS_APPROVED ? 'request-approved-badge' : 'request-pending-badge'}>
                                                {renderValueOrSkeleton(status)}
                                            </Badge>
                                        </span>
                                    </div>
                                </div>
                                <div className="header-buttons">
                                    {!isApproving && status !== STATUS_DRAFT && (
                                        <Button
                                            size="default"
                                            className='request-header-buttons'
                                            onClick={handleCommentsButtonClick}
                                            disabled={!props?.Details}
                                        >
                                            <CommentsIcon/> {props?.Details?.comments && props.Details.comments.length > 0 ? props.Details.comments.length : 'No'} {props?.Details?.comments?.length === 1 ? "comment" : "comments"}
                                        </Button>
                                    )}

                                    { !isApproving && (status === STATUS_APPROVAL_PENDING || status === STATUS_DRAFT) && ( props?.userData?.user_roles?.includes(BUSINESS_USER_ROLE) || props?.userData?.user_roles?.includes(ADMIN_ROLE) || props?.userData?.user_roles?.includes(ADMIN_UI_USER_ROLE)) &&
                                        (
                                            <Button
                                                size="default"
                                                className='request-header-buttons'
                                                disabled={!props?.Details}
                                                onClick={handleNavigate}
                                            >
                                                <EditIcon/>
                                                Edit {status === STATUS_APPROVAL_PENDING ? "Request" : "Draft"}
                                            </Button>
                                        )
                                    }
                                    { !isApproving && status === STATUS_APPROVAL_PENDING && (props?.userData?.user_roles?.includes(ADMIN_ROLE) || props?.userData?.user_roles?.includes(ADMIN_UI_USER_ROLE)) &&
                                        <Button className="orange-button" onClick={()=> setOpenDialog(true)} > Approve </Button>
                                    }
                                    { isApproving && status === STATUS_APPROVAL_PENDING && (props?.userData?.user_roles?.includes(ADMIN_ROLE) || props?.userData?.user_roles?.includes(ADMIN_UI_USER_ROLE)) &&
                                        <Button className="approve-orange-button-disabled" > <CircularProgress size={18} disableShrink sx={{ color: 'black' }}/> </Button>
                                    }
                                </div>
                            </div>
                            <Grid item xs={12} className="request-detail-header-details-grid">
                                {
                                    props?.Details?.type === TS_PLATFORM_TYPE ? (
                                        <React.Fragment>
                                            <div className="request-detail-header-details-div">
                                                Customer name
                                                <div className="request-detail-header-details-subdiv">
                                                    {renderValueOrSkeleton(props?.Details?.config?.customer_details?.customer_name ? props.Details.config.customer_details.customer_name : NA)}
                                                </div>
                                            </div>
                                            <div className="request-detail-header-details-div">
                                                Customer ID
                                                <div className="request-detail-header-details-subdiv">
                                                    {renderValueOrSkeleton(props?.Details?.config?.customer_details?.customer_name ? props.Details.config.customer_details.customer_name : NA)}
                                                </div>
                                            </div>
                                            <div className="request-detail-header-details-div">
                                                Platform
                                                <div className="request-detail-header-details-subdiv">
                                                    {renderValueOrSkeleton(props?.Details?.config?.platform_name ? props.Details.config.platform_name : NA)}
                                                </div>
                                            </div>
                                            <div className="request-detail-header-details-div">
                                                AMG ID
                                                <div className="request-detail-header-details-subdiv">
                                                    {renderValueOrSkeleton(props?.Details?.config?.amg_id ? props.Details.config.amg_id : NA)}
                                                </div>
                                            </div>
                                            <div className="request-detail-header-details-div">
                                                Stream ID
                                                <div className="request-detail-header-details-subdiv">
                                                    {renderValueOrSkeleton(props?.Details?.config?.ts_stream_id ? props.Details.config.ts_stream_id : NA)}
                                                </div>
                                            </div>
                                            <div className="request-detail-header-details-div">
                                                Requested by
                                                <div className="request-detail-header-details-subdiv">
                                                    {renderValueOrSkeleton(props?.Details?.requested_user ? props.Details.requested_user : NA)}
                                                </div>
                                            </div>
                                            <div className="request-detail-header-details-div">
                                                Requested on
                                                <div className="request-detail-header-details-subdiv">
                                                    {renderValueOrSkeleton(props?.Details?.created_at ? getEpochData(props.Details.created_at) : NA)}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="request-detail-header-details-div">
                                                Customer name
                                                <div className="request-detail-header-details-subdiv">
                                                    {renderValueOrSkeleton(props?.Details?.config?.customer_details?.customer_name ? props.Details.config.customer_details.customer_name : NA)}
                                                </div>
                                            </div>
                                            <div className="request-detail-header-details-div">
                                                Supply Partner
                                                <div className="request-detail-header-details-subdiv">
                                                    {renderValueOrSkeleton(props?.Details?.config?.ads_plus_supply_partner_id ? props.Details.config.ads_plus_supply_partner_id : NA)}
                                                </div>
                                            </div>
                                            <div className="request-detail-header-details-div">
                                                AMG ID
                                                <div className="request-detail-header-details-subdiv">
                                                    {renderValueOrSkeleton(props?.Details?.config?.amg_id ? props.Details.config.amg_id : NA)}
                                                </div>
                                            </div>
                                            <div className="request-detail-header-details-div">
                                                Requested by
                                                <div className="request-detail-header-details-subdiv">
                                                    {renderValueOrSkeleton(props?.Details?.requested_user ? props.Details.requested_user : NA)}
                                                </div>
                                            </div>
                                            <div className="request-detail-header-details-div">
                                                Requested on
                                                <div className="request-detail-header-details-subdiv">
                                                    {renderValueOrSkeleton(props?.Details?.created_at ? getEpochData(props.Details.created_at) : NA)}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default RequestDetailsHeader;