import "./index.css"
import React from "react";
import {Button, Grid, Typography} from "@mui/material";
import {getEpochData, getImageName} from "../../utils/helper";
import Badge from "@mui/material/Badge";
import {useNavigate} from "react-router-dom";
import {
    ADMIN_ROLE, ADS_PLUS_PLATFORM_ID,
    AD_OPS_USER_ROLE,
    NON_TS_PLATFORM_TYPE, REQUEST_CONFIG_SESSION_KEY, REQUEST_STATUS_CREATE,
    TS_PLATFORM_TYPE, BUSINESS_USER_ROLE, REQUEST_CHANNEL_DETAILS_KEY, ADMIN_UI_USER_ROLE
} from "../../Constant";
import useWebStorageHelper from "../../utils/sessionStorageHelper";

const PlatformHeader = (props) => {
    const navigation = useNavigate();
    const [userEmail, setUserEmail] = React.useState("");
    const [role, setRole] = React.useState("");

    React.useEffect(() => {
        if(props?.userData){
            props?.userData?.user_roles?.includes(AD_OPS_USER_ROLE) ?
                setRole(AD_OPS_USER_ROLE) :
            props?.userData?.user_roles?.includes(ADMIN_UI_USER_ROLE) ?
                setRole(ADMIN_UI_USER_ROLE) :
            props?.userData?.user_roles?.includes(ADMIN_ROLE) ?
                setRole(ADMIN_ROLE) :
            setRole("");
            setUserEmail(props?.userData?.email);
        }
    }, [props?.userData])

    const generateNewRequestTS = async () =>{
        let sessionData = {
            "ts_channel_id": props?.Detail?.channel_id ? props.Detail.channel_id : "",
            "ss_core_supply_router_id": props?.Detail?.supply_router_id ? props.Detail.supply_router_id : 0,
            "platform_amg_id": props?.Detail?.platform_id ? props?.Detail?.platform_id : "",
            "type": TS_PLATFORM_TYPE,
            "status": REQUEST_STATUS_CREATE,
            "user_email": userEmail,
            "creation_type": "ss-ads-core",
            "platform_name": props?.platformName,
        }
        let requestChannelData = {
            channel_name: props?.Detail?.name,
            channel_id: props?.Detail?.channel_id,
            platformDealTypeInventory: props?.platformDealTypeInventory,
        }
        useWebStorageHelper.setItemSession(REQUEST_CONFIG_SESSION_KEY, sessionData);
        useWebStorageHelper.setItemSession(REQUEST_CHANNEL_DETAILS_KEY, requestChannelData);
        navigation(`/dashboard/requests/new-request`);
    }

    const generateNewRequestNonTS = () =>{
        let sessionData = {
            "ts_channel_id": "",
            "ss_core_supply_router_id": 0,
            "platform_amg_id": ADS_PLUS_PLATFORM_ID,
            "type": NON_TS_PLATFORM_TYPE,
            "status": REQUEST_STATUS_CREATE,
            "user_email": userEmail,
            "creation_type": "ss-ads-plus",
            "platform_name": props?.Detail?.name,
        }
        useWebStorageHelper.setItemSession(REQUEST_CONFIG_SESSION_KEY, sessionData);
        navigation(`/dashboard/requests/new-request`);
    }

    return (
        <React.Fragment>
            <Grid item xs={12}  className="platform-header">
                <Grid container>
                    <Grid item xs={12} md={1}>

                    <div className="img-div">
                            {props?.headerType === "Channel" ? (
                                <Typography className="img-typography">
                                    {getImageName(props?.Detail?.name)}
                                </Typography>
                            ) : (
                                props?.Detail?.platform_logo_url? (
                                    <img src={props?.Detail?.platform_logo_url} alt="logo" />
                                ) : (
                                    <Typography className="img-typography">
                                        {getImageName(props?.Detail?.name)}
                                    </Typography>
                                )
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={10} >
                        <div>
                            <div className="platform-head-div">{props?.headerType}
                            </div>
                            <div className="platfrom-head-name-div">{props?.Detail?.name}
                                <span>
                                    <Badge className="platform-live-badge">{props?.status}</Badge>
                                </span>
                            </div>

                            {props?.headerType==="Platform" ? (<Grid item xs={12} className="platform-detail-header-details-grid">
                            <div className="platform-detail-header-details-div">Amagi ID
                                <div
                                    className="platform-detail-header-details-subdiv">{props?.Detail?.amg_id}</div>
                            </div>
                            <div className="platform-detail-header-details-div">Region
                                <div
                                    className="platform-detail-header-details-subdiv">{props?.Detail?.region}</div>
                            </div>
                            <div className="platform-detail-header-details-div">Channels
                                <div
                                    className="platform-detail-header-details-subdiv">{props?.Detail?.channels}</div>
                            </div>
                            <div className="platform-detail-header-details-div">Routers
                                <div
                                    className="platform-detail-header-details-subdiv">{props?.Detail?.routers}</div>
                            </div>
                            <div className="platform-detail-header-details-div">Deal Type
                                <div
                                    className="platform-detail-header-details-subdiv">{props?.Detail?.deal_type?.[0] || ""}</div>
                            </div>
                            </Grid>):(
                                <Grid item xs={12} className="platform-detail-header-details-grid">
                                    <div className="platform-detail-header-details-div">Channel ID
                                        <div
                                            className="platform-detail-header-details-subdiv">{props?.Detail?.channel_id}</div>
                                    </div>
                                    <div className="platform-detail-header-details-div">Supply Partner
                                        <div
                                            className="platform-detail-header-details-subdiv">{props?.Detail?.supply_partner_name ? (props?.Detail?.supply_partner_name) : ("NA")}</div>
                                    </div>
                                    <div className="platform-detail-header-details-div">Self Serve Policy
                                        <div
                                            className="platform-detail-header-details-subdiv">{props?.Detail?.policy ? "Enabled":"Not Enabled"}</div>
                                    </div>
                                    <div className="platform-detail-header-details-div">Last Updated
                                        <div
                                            className="platform-detail-header-details-subdiv">{getEpochData(props?.Detail?.updated_at)}</div>
                                    </div>
                                </Grid>
                            )}
                        </div>
                    </Grid>

                    {props?.headerType==="Channel"  && props?.platformType===TS_PLATFORM_TYPE && ( role === AD_OPS_USER_ROLE || role === ADMIN_ROLE || role === ADMIN_UI_USER_ROLE) && props?.Detail?.policy
                        && ( <Grid item xs={12} md={1}><Button className="enable-ads-plus-btn" onClick={()=>generateNewRequestTS()}> Enable Ads Plus</Button></Grid>) }

                    {props?.headerType==="Platform" && props?.platformType===NON_TS_PLATFORM_TYPE && ( role === AD_OPS_USER_ROLE || role === ADMIN_ROLE || role === BUSINESS_USER_ROLE  || role === ADMIN_UI_USER_ROLE)
                        && ( <Grid item xs={12} md={1}><Button className="enable-ads-plus-btn" onClick={()=>generateNewRequestNonTS()}> Request new delivery</Button></Grid>) }

                </Grid>
            </Grid>
        </React.Fragment>
    )
}
export default PlatformHeader
