
import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { downloadReport } from "../../../api/dowloadReportSupplyTransparency";
import { getSupplyTransparencyReport } from "../../../api/getSupplyTransparencyReport";
import SupplyTransparencyReportBreadCrumbs from "../../../Components/BreadCrumbs/AvailsSupplyBreadCrumbs";
import Notification from "../../../Components/Notification";
import ReportGenerationInput from "../../../Components/Report/ReportGenerationInput";
import ReportManageColumn from "../../../Components/Report/ReportManageColumn";
import ReportPreviewTable from "../../../Components/Report/ReportPreviewTable";
import {
    SUPPLY_TRANSPARENCY_COLUMNS_FOR_ADSPLUS,
    SUPPLY_TRANSPARENCY_COLUMNS_FOR_CORE,
    TODAYS_DATE,
    USER_DATA_KEY,
    CSV_FORMAT,
    DEFAULT_SELECTED_COLUMN_STR,
    STR, DEFAULT_SELECTED_COLUMN_STR_FOR_CORE
} from "../../../Constant";
import { getLocalStorageData } from "../../../utils/localStorageHelper";

import "./index.css"


const SupplyTransparencyReport = () => {


    const [isOpen, setIsOpen] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState("")
    const [alertType, setAlertType] = useState("")
    const [EmailId, setEmailId] = useState("")

    const [take, setTake] = useState(100);
    const [supplyTransparencyTableData, setSupplyTransparencyTableData] = useState([])
    const [isManageColumnSidebarOpen, setIsManageColumnSidebarOpen] = useState(false);
    const [tableHeaderStr, setTableHeaderStr] = useState(SUPPLY_TRANSPARENCY_COLUMNS_FOR_ADSPLUS);
    const [reportNameStr, setReportNameStr] = useState("");
    const [dataSource, setDataSource] = useState("SS Ads+");
    const [loading, setLoading] = useState(true)
    const [contentLength, setContentLength] = useState(0);


    const [selectedColumns, setSelectedColumns] = useState(DEFAULT_SELECTED_COLUMN_STR)

    const [checkedIds, setCheckedIds] = useState(() => {
        const initialCheckedIds = {};
        SUPPLY_TRANSPARENCY_COLUMNS_FOR_ADSPLUS.forEach(column => {
            initialCheckedIds[column.id] = true;
        });
        return initialCheckedIds;
    });


    const [where, setWhere] = useState({
        date: { equals: TODAYS_DATE },
        stream_id: {
        },
        supply_partner_id: {},
        supply_partner_name: {},
        supply_tag_id: {},
        total_ad_request: {},
        total_impression: {}
    });

    const [orderBy,setOrderBy]=useState(
    {
        total_ad_request: "desc"
    }
    )

    useEffect(() => {
        getSupplyTransparencyReportData(where)
    }, []);

    useEffect(() => {
        const updateCheckedIds = () => {
            const updatedCheckedIds = {};
            const columnsToUse = dataSource === 'SS Ads+' ? SUPPLY_TRANSPARENCY_COLUMNS_FOR_ADSPLUS : SUPPLY_TRANSPARENCY_COLUMNS_FOR_CORE;
            columnsToUse.forEach(column => {
                updatedCheckedIds[column.id] = true;
            });
            setCheckedIds(updatedCheckedIds);
        };

        updateCheckedIds();
    }, [dataSource]);


    const handleSetWhere = (new_where) =>{
        setWhere(new_where)
    }

    const handleReportDownload = async (where) => {
        try {
            const user_data = getLocalStorageData(USER_DATA_KEY, true);
            let userEmail = user_data?.email || ""
            setEmailId(userEmail)
            const requestData = {
                where,
                select: selectedColumns,
                user_id: userEmail, // Assuming userId is defined somewhere in your component
                format: CSV_FORMAT,
                name: reportNameStr
            };
            await downloadReport(dataSource, requestData);
        } catch (error) {
            // Handle errors
            console.error("Error downloading report:", error);
            // You can also set notification or alert here
        }
    };

    const handleColumnSet = (columns) => {
        setSelectedColumns(columns)
    }



    const handleNotificationClose = () => {
        setIsOpen(false);
    }
    const handleNotificationOpen = () => {
        setIsOpen(true);
    }
    const handleSetNotificationMessage = (message) => {
        setNotificationMessage(message)
    }

    const handleSetAlertType = (type) => {
        setAlertType(type)
    }

    const navigation = useNavigate();


    const getSupplyTransparencyReportData = async (where) => {

        setLoading(true)
        try {
            const supplyTransparencyReport = await getSupplyTransparencyReport( dataSource, where, take, orderBy);
            setSupplyTransparencyTableData(supplyTransparencyReport);
            setContentLength(supplyTransparencyReport.length)
            setLoading(false)
        } catch (error) {
            // Handle errors
            console.error("Error fetching supply transparency report:", error);
            setLoading(false);
            return null;
        }
    };


    //Function for setting reportName
    const reportNaming = (report_name) => {
        setReportNameStr(report_name)
    }

    const changeDataSource = (newDataSource) => {
        setDataSource(newDataSource);
        if(newDataSource === "SS Ads+"){
            setTableHeaderStr(SUPPLY_TRANSPARENCY_COLUMNS_FOR_ADSPLUS)
            setSelectedColumns(DEFAULT_SELECTED_COLUMN_STR)
        }else if(newDataSource === "SS Core"){
            setTableHeaderStr(SUPPLY_TRANSPARENCY_COLUMNS_FOR_CORE)
            setSelectedColumns(DEFAULT_SELECTED_COLUMN_STR_FOR_CORE)
        }
    }


    //Function to handle breadCrum navigation
    const onClickBreadCrumbsLink = (link) => {
        navigation(link);
    };

    const openManageColumnSidebar = () => {
        setIsManageColumnSidebarOpen(true)
    }

    const changeTableHeader = (newArrangement) => {
        setTableHeaderStr(newArrangement)
    }


    //Function to update Query Params
    const updateWhere = (key, value) => {
        setWhere(prevWhere => ({
            ...prevWhere,
            [key]: value
        }));
    };
    // updateWhere('total_impression', { gte: 20 })

    const setCheakedHeaders = (id) => {
        setCheckedIds(id)
    }


    return (
        <React.Fragment>
            {isManageColumnSidebarOpen && (
                <ReportManageColumn
                    isFilterSidebarOpen={isManageColumnSidebarOpen}
                    closeFilterSidebar={() => setIsManageColumnSidebarOpen(false)}
                    handleTableHeader={changeTableHeader}
                    setCheckedHeaderIds={setCheakedHeaders}
                    checkedHeaderIds={checkedIds}
                    tableLength={contentLength}
                    handleColumns={handleColumnSet}
                    dataSource={dataSource}

                />
            )}
            <Grid item xs={12} className="amg-bread-crumbs">
                <SupplyTransparencyReportBreadCrumbs
                    onClickBreadCrumbsLink={onClickBreadCrumbsLink}
                />
            </Grid>
            <Grid item xs={12} className="report-heading-grid">
                <Typography className="report-heading-typography">New supply transparency report</Typography>
            </Grid>
            <Grid item xs={12}>
                <ReportGenerationInput
                    changeReportName={reportNaming}
                    reportName={reportNameStr}
                    changeDataSource={changeDataSource}
                    dataSource={dataSource}
                    queryWhereInput={updateWhere}
                    NotificationOpen={handleNotificationOpen}
                    NotificationClose={handleNotificationClose}
                    SetMessage={handleSetNotificationMessage}
                    SetAlert={handleSetAlertType}
                    setTheWhere={handleSetWhere}
                    ReportDownload={handleReportDownload}
                    getPreviewData ={getSupplyTransparencyReportData}

                />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "16px" }}>

                <ReportPreviewTable
                    reportType = {STR}
                    tableHeader={tableHeaderStr}
                    openColumnSidebar={openManageColumnSidebar}
                    tableData={supplyTransparencyTableData}
                    isLoading={loading}
                    tableLength={contentLength}
                />
                </Grid>
                <Notification
                    open={isOpen}
                    message={notificationMessage}
                    handleClose={handleNotificationClose}
                    type={alertType}
                />
        </React.Fragment>
    )
}

export default SupplyTransparencyReport