import React, {useEffect, useState}  from "react"
import {checkSubstringInArray, getCurrentTime, getInitialPage} from "./utils/helper";
import {Grid} from "@mui/material";
import Header from "./Components/Header";
import Body from "./Components/Body";
import {ADMIN_UI_USER_ROLE, USER_ROLE_TO_PATH_MAPPING} from "./Constant";

function Container(props){
    const [userData, setUserData] = useState({})

    useEffect(() => {
        const getLocalUserData = props?.userData || {}
        const getCurrentEpochTime = getCurrentTime();
        const userRoles = props?.userData?.user_roles || []
        if (
            getLocalUserData?.expires_on &&
            getLocalUserData.expires_on > getCurrentEpochTime
            && userRoles?.length > 0
        ) {
            setUserData(getLocalUserData);
            if(userRoles.includes(ADMIN_UI_USER_ROLE) && checkSubstringInArray(USER_ROLE_TO_PATH_MAPPING[ADMIN_UI_USER_ROLE], props.currentPath)) {
                console.log(props.currentPath)
            }else {
                let initialPage = getInitialPage(userRoles);
                window.location.href = initialPage
            }
        }
    }, []);

    return(
        <Grid container className="amg-content-div">
            {
                props.isAuthenticated &&
                (
                    <React.Fragment>
                        <Header userData={userData}/>
                        <Body userData={userData}/>
                    </React.Fragment>
                )
            }
        </Grid>
    )
}

export default Container