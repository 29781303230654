import React, {useEffect, useState} from "react";
import {
    Bar,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Line,
    ComposedChart,
    Legend, Tooltip, ResponsiveContainer
} from "recharts";
import {Grid} from "@mui/material";
import "./index.css"
import PropTypes from "prop-types";
import {addPercentage, formatNumber, formatRevenue, getDateLabel} from "../../utils/helper";
import {
    AD_REQUEST_AND_IMPRESSION,
    DEMAND_MARKET_SUMMERY,
    MARGIN_AND_FILL_RATE,
    OPEN_MARKET_SUMMERY
} from "../../Constant";
import CustomTooltip from "./CustomToolTip";

function BarChartComponent(props) {
    const [mappingData, setMappingData] = useState([]);
    const [barViewType, setBarViewType] = useState("");
    const [hideLegend, setHideLegend] = useState([]);
    const defaultBarSize = 20; 
    const reducedBarSize = 7;

    useEffect(() => {
        if (props.barChartData?.length > 0 && props.type !== "") {
            let newMappingData = []
            if (props.type === AD_REQUEST_AND_IMPRESSION) {
                props.barChartData.map((data) => {
                    newMappingData.push({
                        "date": data?.date,
                        "Revenue": data?.revenue,
                        "Ad Requests": data?.ad_requests,
                        "Impressions": data.impressions,
                    })
                    return true
                })
            } else if (props.type === MARGIN_AND_FILL_RATE) {
                props.barChartData.map((data) => {
                    newMappingData.push({
                        "date": data?.date,
                        "Revenue": data?.revenue,
                        "Fill Rate": data?.fill_rate,
                        "Margin": data.margin_per,
                    })
                    return true
                })
            } else if (props.type === DEMAND_MARKET_SUMMERY) {
                props.barChartData.map((data) => {
                    newMappingData.push({
                        "date": data?.date,
                        "Revenue": data?.revenue,
                        "Impressions": data?.impressions
                    })
                    return true
                })
            } else if (props.type === OPEN_MARKET_SUMMERY) {
                props.barChartData.map((data) => {
                    let revenue = 0
                    let impression = 0

                    if (data?.["open_market_summery"]?.length > 0) {
                        data?.open_market_summery.map((openMarketData) => {
                            revenue = openMarketData?.revenue + revenue
                            impression = openMarketData?.impressions + impression
                            return true
                        })
                    }

                    newMappingData.push({
                        "date": data?.date,
                        "Revenue": revenue,
                        "Impressions": impression
                    })
                    return true
                })
            }
            newMappingData.sort((a, b) =>  new Date(a.date) - new Date(b.date));
            setMappingData(newMappingData)
            setBarViewType(props.type)
        }
    }, [props?.barChartData, props.type, props.viewType])

    const onClickLegend = (e) => {
        const value = e?.value
        const getStateHideLegend = Object.assign([], hideLegend)
        if(getStateHideLegend.indexOf(value) > -1){
            const getIndexOfValue = getStateHideLegend.indexOf(value)
            getStateHideLegend.splice(getIndexOfValue, 1)
        }else {
            getStateHideLegend.push(value)
        }
        setHideLegend(getStateHideLegend)
    }

    return (
        <Grid item xs={12} className="barchart-container">
            <ResponsiveContainer>
                <ComposedChart className="barchart-main-div" data={mappingData} width={1500} height={640}>
                    <XAxis
                        dataKey="date"
                        tickFormatter={getDateLabel}
                        interval={props.viewInterval || 2}
                    />
                    // YAxis Left Side
                    <YAxis
                        dataKey="Revenue"
                        orientation="left"
                        yAxisId="left"
                        tickFormatter={formatRevenue}
                        width={80}
                    />
                    // YAxis Right Side
                    {barViewType === AD_REQUEST_AND_IMPRESSION && <YAxis
                        dataKey="Ad Requests"
                        orientation="right"
                        yAxisId="right"
                        tickFormatter={formatNumber}
                        width={120}
                    />}
                    {barViewType === MARGIN_AND_FILL_RATE && <YAxis
                        dataKey="Margin"
                        orientation="right"
                        yAxisId="right"
                        tickFormatter={addPercentage}
                    />}
                    {(barViewType === DEMAND_MARKET_SUMMERY || barViewType === OPEN_MARKET_SUMMERY) && <YAxis
                        dataKey="Impressions"
                        orientation="right"
                        yAxisId="right"
                        tickFormatter={formatNumber}
                        domain={['0', 'dataMax + 10000']}
                        width={100}
                    />}
                    <Legend onClick={(e) => onClickLegend(e)}/>
                    <CartesianGrid strokeDasharray="3 3" horizontal={true} stroke="#FFFFFF33" vertical={false}/>
                    // Bar View
                    <Bar
                        dataKey="Revenue"
                        fill="#614bd24d"
                        barSize={mappingData.length > 31 ? reducedBarSize : defaultBarSize}
                        yAxisId="left"
                        fillOpacity={hideLegend.includes("Revenue") ? 0.3 : 1}
                    />
                    // First Line
                    {barViewType === AD_REQUEST_AND_IMPRESSION && <Line
                        type="monotone"
                        dataKey="Ad Requests"
                        stroke="#4999E9"
                        yAxisId="right"
                        fill="#4999E9"
                        strokeOpacity={hideLegend.includes("Ad Requests") ? 0.3 : 1}
                        dot={false}
                    />}
                    {barViewType === MARGIN_AND_FILL_RATE && <Line
                        type="monotone"
                        dataKey="Margin"
                        stroke="#4999E9"
                        yAxisId="right"
                        fill="#4999E9"
                        dot={false}
                        strokeOpacity={hideLegend.includes("Margin") ? 0.3 : 1}
                    />}
                    {(barViewType === DEMAND_MARKET_SUMMERY || barViewType === OPEN_MARKET_SUMMERY)  && <Line
                        type="monotone"
                        dataKey="Impressions"
                        stroke="#4999E9"
                        yAxisId="right"
                        fill="#4999E9"
                        dot={false}
                        strokeOpacity={hideLegend.includes("Impressions") ? 0.3 : 1}
                    />}
                    // Second Line
                    {barViewType === AD_REQUEST_AND_IMPRESSION && <Line
                        type="monotone"
                        dataKey="Impressions"
                        stroke="#137A5C"
                        yAxisId="right"
                        fill="#137A5C"
                        dot={false}
                        strokeOpacity={hideLegend.includes("Impressions") ? 0.3 : 1}
                    />}
                    {barViewType === MARGIN_AND_FILL_RATE && <Line
                        type="monotone"
                        dataKey="Fill Rate"
                        stroke="#137A5C"
                        yAxisId="right"
                        fill="#137A5C"
                        dot={false}
                        strokeOpacity={hideLegend.includes("Fill Rate") ? 0.3 : 1}
                    />}
                    <Tooltip content={<CustomTooltip/>}/>
                </ComposedChart>
            </ResponsiveContainer>
        </Grid>
    )
}

BarChart.propTypes = {
    "barChartData": PropTypes.arrayOf(PropTypes.shape(
        {
            "date": PropTypes.string.isRequired,
            "ad_requests": PropTypes.number.isRequired,
            "impressions": PropTypes.number.isRequired,
            "revenue": PropTypes.number.isRequired,
            "fill_rate": PropTypes.number.isRequired,
            "margin_per": PropTypes.number.isRequired
        }
    ).isRequired).isRequired,
    "type": PropTypes.string.isRequired,
    viewInterval: PropTypes.number,
}

export default BarChartComponent;
