import React, {useEffect, useState} from "react";
import "./index.css"
import AvailsSupplyBreadCrumbs from "../../../Components/BreadCrumbs/AvailsSupplyBreadCrumbs";
import {Grid, Skeleton, Tab, Tabs, Typography} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {getCurrentMonthAndYearString, isEmpty, RemoveDecimalUSANumberFormat} from "../../../utils/helper";
import ReportSimpleInput from "../../../Common/ReportSimpleInput";
import getAvailsValues from "../../../api/getAvailsValues";
import {convertToNewLabelValueFormat} from "../../../utils/helper";
import {
    AVAILS_FILTER_CONFIG,
    DATA_IN_LOADING_STATE,
    NO_CRITERIA_SELECTED,
    NO_DATA_FOUND_MESSAGE,
    NO_FILTER_APPLIED,
    NO_REPORT_NAME_ENTERED,
    SAME_CRITERIA_TYPE_MESSAGE,
    WARNING_NOTIFICATION_TYPE,
    AVAILS_TAB,
    AVAILS,
    AVAILS_TAB_VIEW,
    AVAILS_AVAILS_TAB_COLUMNS,
    AVAILS_SUPPLY_LIST_TAB_COLUMN,
    OPERATOR_MAP_STRING,
    OPERATOR_MAP_NUMBER,
    OPERATOR_MAP_BOOLEAN,
    SAME_CRITERIA_TYPE_ON_CHANGE,
    AVAILS_WHERE_QUERY,
    ERROR_NOTIFICATION_TYPE,
    AVAILS_API_ERROR,
    AVAILS_REPORT_DOWNLOAD, USER_DATA_KEY, REPORT_LANDING_PAGE
} from "../../../Constant";
import {getAllFiltersAvailsData} from "../../../__mock__/getAllFiltersAvailsData";
import Notification from "../../../Components/Notification";
import FilterTableWithoutSearch from "../../../Components/TableTopFilters/FilterContentWithoutSearch";
import TableReports from "../../../Components/Tables/TableReports";
import ReportPreviewTable from "../../../Components/Report/ReportPreviewTable";
import TableAvailsReport from "../../../Components/Tables/TableAvailsReport";
import {AVAILS_DATA} from "../../../__mock__/getAvailsData";
import {SUPPLY_LIST_DATA} from "../../../__mock__/getSupplyList";
import getAvailsReport from "../../../api/getAvailsReport";
import {getLocalStorageData} from "../../../utils/localStorageHelper";
import downloadAvailsReport from "../../../api/downloadAvailsReport";


const AvailsSupplyDetails = () => {
    const [currentMonthYear, setCurrentMonthYear] = useState("");
    const [filterData, setFilterData] = useState([]);
    const [reportName, setReportName] = useState("");
    const [selectedCriteria, setSelectedCriteria] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState("")
    const [notificationMessage, setNotificationMessage] = useState("")
    const [mainCriteria, setMainCriteria] = useState({...AVAILS_FILTER_CONFIG});
    const [selectedTab, setSelectedTab] = useState(AVAILS_TAB)
    const [tableHeaderStr, setTableHeaderStr] = useState(AVAILS_AVAILS_TAB_COLUMNS);
    const [reportCount, setReportCount] = useState(null)
    const [tableData,setTableData]=useState([])
    const [availsData, setAvailsData]=useState({})
    const [loading, setLoading] = useState(false)
    const navigation = useNavigate();


    useEffect(() => {
        const getCurrentMonthYear = getCurrentMonthAndYearString()
        setCurrentMonthYear(getCurrentMonthYear)
        loadData(AVAILS_WHERE_QUERY)
    }, [])

    const loadData = async (config = {}) => {
        setLoading(true)
        const response = await getAvailsReport(config)
        if(response?.avails_list?.result_set.length>0){
            setTableData(response.avails_list.result_set)
            setReportCount(response.avails_list.total_count)
            setAvailsData(response)
            setLoading(false)
        }else {
            setTableData(response.avails_list.result_set)
            setReportCount(response.avails_list.total_count)
            setAvailsData(response)
            setShowNotification(true)
            setNotificationType(ERROR_NOTIFICATION_TYPE)
            setNotificationMessage(AVAILS_API_ERROR)
            setLoading(false)
        }
    }



    const onClickBreadCrumbsLink = (link) => {
        navigation(link);
    };

    const addNewFilter = () => {
        const getStateFilterData = Object.assign([], filterData)
        const getStateMainCriteria = Object.assign({}, mainCriteria)
        getStateFilterData.push({
            "value": "",
            "fields": getStateMainCriteria,
            "operator_value": "",
            "operator": [],
            "options": [],
            "options_value": [],
            "type": "string",
            "where_criteria": "",
            "where_operator": "",
            "where_value": []
        })
        setFilterData(getStateFilterData)
    }

    const changeReportName = (value) => {
        setReportName(value)
    }

    const onChangeMainCriteria = (value, index, group) => {
        const getStateFilterData = Object.assign([], filterData)
        const getSelectedCriteria = Object.assign([], selectedCriteria)
        const getStateMainCriteria = Object.assign({}, mainCriteria)

        if(!isEmpty(getStateFilterData[index]["value"]) && getSelectedCriteria.indexOf(getStateFilterData[index]["value"]) >= 0){
            const getIndexSelctedCriteria = getSelectedCriteria.indexOf(getStateFilterData[index]["value"])
            getSelectedCriteria.splice(getIndexSelctedCriteria, 1);
        }

        if(getSelectedCriteria.indexOf(value) < 0 && isEmpty(getStateFilterData[index]["operator_value"])) {
            getStateFilterData[index]["value"] = value
            let getFieldsData = getStateFilterData[index]?.fields?.[group]?.find((data) => data.label === value)
            getStateFilterData[index]["operator"] = getFieldsData?.operator
            getStateFilterData[index]["type"] = getFieldsData?.type
            getStateFilterData[index]["where_criteria"] = getFieldsData.value
            getSelectedCriteria.push(value)

            getStateMainCriteria[group] = getStateMainCriteria[group].filter((item) => item.label !== value)

            setFilterData(getStateFilterData)
            setSelectedCriteria(getSelectedCriteria)
            setMainCriteria(getStateMainCriteria)
        }else if(!isEmpty(getStateFilterData[index]["operator_value"])){
            setShowNotification(true)
            setNotificationType(WARNING_NOTIFICATION_TYPE)
            setNotificationMessage(SAME_CRITERIA_TYPE_ON_CHANGE)
        }else {
            setShowNotification(true)
            setNotificationType(WARNING_NOTIFICATION_TYPE)
            setNotificationMessage(SAME_CRITERIA_TYPE_MESSAGE)
        }

    }

    const onChangeOperator = async (value, index, type) => {
        const getStateFilterData = Object.assign([], filterData)
        getStateFilterData[index]["operator_value"] = value
        if(type === "string"){
            getStateFilterData[index]["where_operator"] = OPERATOR_MAP_STRING[value]
        }

        if(type === "integer"){
            getStateFilterData[index]["where_operator"] = OPERATOR_MAP_NUMBER[value]
        }

        if(type === "boolean"){
            getStateFilterData[index]["where_operator"] = OPERATOR_MAP_BOOLEAN[value]
        }
        const valueResponse = await getAvailsValues(getStateFilterData[index]["where_criteria"]);
        const data =convertToNewLabelValueFormat(valueResponse)

        getStateFilterData[index]["options"] = data
        setFilterData(getStateFilterData)
    }


    const onChangeValues = (value, index, type) => {
        // Create a copy of filterData
        const getStateFilterData = [...filterData];


        if (type === "boolean") {
            // Handle boolean type specially
            getStateFilterData.forEach((filter, idx) => {
                if (idx === index) {
                    getStateFilterData[idx]["options_value"] = value;
                    getStateFilterData[idx]["where_value"] = value === "true";
                } else {
                    getStateFilterData[idx]["options_value"] = "";
                    getStateFilterData[idx]["where_value"] = false;
                }
            });
        } else if (type === "integer") {
            const parsedValue = value !== "" ? parseInt(value, 10) : "";
            getStateFilterData[index]["options_value"] = parsedValue;
            getStateFilterData[index]["where_value"] = parsedValue;
        } else {
            // Create copies of options_value and where_value to avoid direct mutations
            const optionsValueCopy = Array.isArray(getStateFilterData[index]["options_value"]) ? [...getStateFilterData[index]["options_value"]] : [];
            const whereValueCopy = Array.isArray(getStateFilterData[index]["where_value"]) ? [...getStateFilterData[index]["where_value"]] : [];


            if (value === "all" && type === "string") {
                if (optionsValueCopy.length === getStateFilterData[index]["options"].length) {
                    getStateFilterData[index]["options_value"] = [];
                    getStateFilterData[index]["where_value"] = [];
                } else {
                    const getAllValue = getStateFilterData[index]["options"]?.map(item => item.label);
                    getStateFilterData[index]["options_value"] = getAllValue;
                    getStateFilterData[index]["where_value"] = getAllValue;
                }
            } else if (typeof optionsValueCopy === "object" && optionsValueCopy.indexOf(value) > -1 && type === "string") {
                const getIndex = optionsValueCopy.indexOf(value);
                optionsValueCopy.splice(getIndex, 1);

                whereValueCopy.splice(getIndex, 1);
                getStateFilterData[index]["options_value"] = optionsValueCopy;
                getStateFilterData[index]["where_value"] = whereValueCopy;
            } else if (type === "string" && typeof optionsValueCopy === "object") {
                optionsValueCopy.push(value);
                whereValueCopy.push(value);
                getStateFilterData[index]["options_value"] = optionsValueCopy;
                getStateFilterData[index]["where_value"] = whereValueCopy;

            }
        }

        setFilterData(getStateFilterData);
    }



    const handleClose = () => {
        setShowNotification(false)
        setNotificationType("")
        setNotificationMessage("")
    }

    const onCancelFilter = () => {
        setFilterData([])
        setSelectedCriteria([])
        setMainCriteria({...AVAILS_FILTER_CONFIG})
    }

    const onUpdatePreview = () => {
        const getStateFilterData = filterData.find((data) => (data?.value === "" || data?.operator_value === "" || data?.options_value === "") && data)
        if(!isEmpty(getStateFilterData)){
            setNotificationType(WARNING_NOTIFICATION_TYPE)
            setNotificationMessage(NO_CRITERIA_SELECTED)
            setShowNotification(true)
        }else if(isEmpty(filterData) && selectedCriteria.length!==0){
            setNotificationType(WARNING_NOTIFICATION_TYPE)
            setNotificationMessage(NO_FILTER_APPLIED)
            setShowNotification(true)
        }else {
            const createWhereFilter = {}
            filterData.map((filter) => {
                createWhereFilter[filter.where_criteria] = {}
                createWhereFilter[filter.where_criteria][filter.where_operator] = filter.where_value

                return true
            })
            loadData({"where": createWhereFilter})
        }
    }
    const saveGenerate = async () => {
        const getStateFilterData = filterData.find((data) => (data?.value === "" || data?.operator_value === "" || data?.options_value === "") && data)
        if(!isEmpty(getStateFilterData)){
            setNotificationType(WARNING_NOTIFICATION_TYPE)
            setNotificationMessage(NO_CRITERIA_SELECTED)
            setShowNotification(true)
        }else if(isEmpty(reportName)){
            setNotificationType(WARNING_NOTIFICATION_TYPE)
            setNotificationMessage(NO_REPORT_NAME_ENTERED)
            setShowNotification(true)
        }else {
            const createWhereFilter = {}
            const getUserData = getLocalStorageData(USER_DATA_KEY, true)
            const requestDownload = AVAILS_REPORT_DOWNLOAD
            filterData.map((filter) => {
                createWhereFilter[filter.where_criteria] = {}
                createWhereFilter[filter.where_criteria][filter.where_operator] = filter.where_value

                return true
            })
            requestDownload.report_download.name = reportName
            requestDownload.report_download.user_id = getUserData?.email
            //requestDownload.report_download.organisation_id = getUserData?.org_id
            loadData({"where": createWhereFilter})
            await downloadAvailsReport({"where": createWhereFilter,"report_details":requestDownload.report_download })
            setTimeout(() => {
                navigation(`/${REPORT_LANDING_PAGE}`);
            }, 4000);
        }
    }

    const onDeleteCriteria = (value,index) => {
        const getStateFilterData = Object.assign([], filterData)
        const getSelectedCriteria = Object.assign([], selectedCriteria)
        const getStateMainCriteria = Object.assign({}, mainCriteria)

        if(isEmpty(value)){
            getStateFilterData.splice(index, 1);

            setFilterData(getStateFilterData)
        }else if(!isEmpty(value)){
            let getMainCriteriaData = {}
            let getMainCriteriaGroup = ""
            Object.keys(AVAILS_FILTER_CONFIG).map((key) => {
                AVAILS_FILTER_CONFIG[key].map((data) => {
                    if(data.label === value){
                        getMainCriteriaData = data
                        getMainCriteriaGroup = key
                    }

                    return true
                })
            })

            if(!isEmpty(getMainCriteriaData) && !isEmpty(getMainCriteriaGroup)){
                getStateMainCriteria[getMainCriteriaGroup].push(getMainCriteriaData)
                const getIndex = getSelectedCriteria.indexOf(value)
                getStateFilterData.splice(index, 1);
                getSelectedCriteria.splice(getIndex, 1);

                setFilterData(getStateFilterData)
                setSelectedCriteria(getSelectedCriteria)
                setMainCriteria(getStateMainCriteria)
            }else {
                setNotificationType(WARNING_NOTIFICATION_TYPE)
                setNotificationMessage(DATA_IN_LOADING_STATE)
                setShowNotification(true)
            }
        }
    }

    const handleTabChange = (event, newValue) =>{
        const getStateAvailsData = Object.assign({}, availsData)
        setSelectedTab(newValue);
        if(newValue==="Avails"){
            setTableHeaderStr(AVAILS_AVAILS_TAB_COLUMNS)
            setTableData(getStateAvailsData?.avails_list?.result_set)
        }
        else{
            setTableHeaderStr(AVAILS_SUPPLY_LIST_TAB_COLUMN)
            setTableData(getStateAvailsData?.supply_list?.result_set)
        }
    }



    return (
        <Grid container className="avail-report-container">
            <Grid item xs={12} className="amg-bread-crumbs">
                <AvailsSupplyBreadCrumbs
                    onClickBreadCrumbsLink={onClickBreadCrumbsLink}
                    currentMonthYear={currentMonthYear}
                />
            </Grid>
            <Grid item xs={12} className="report-heading">
                <Typography className="report-heading-typography"> New avails and supply list report ({currentMonthYear}) </Typography>
            </Grid>
            <Grid item xs={12} className="report-input">
                <ReportSimpleInput
                    formFields={filterData}
                    addNewFilter={addNewFilter}
                    reportName={reportName}
                    changeReportName={changeReportName}
                    onChangeMainCriteria={onChangeMainCriteria}
                    onChangeOperator={onChangeOperator}
                    onChangeValues={onChangeValues}
                    showReportName={true}
                    onCancel={onCancelFilter}
                    onUpdatePreview={onUpdatePreview}
                    saveGenerate={saveGenerate}
                    onDeleteCriteria={onDeleteCriteria}
                />
            </Grid>
            <Grid  item xs={12} className="report-table-preview-grid" sx={{marginTop:"16px"}}>
                <Grid container className="report-table-preview-heading">
                    <span className="report-table-preview-heading-span">Report preview</span>
                    <span className="report-table-preview-note-span">Preview upto 20 results. To view the full report, please “Save & generate” to download it</span>
                </Grid>
                <Grid  className="report-table-preview-detail-avail">
                    <div className="platform-detail-header-details-div">Total 30s
                        <div className="platform-detail-header-details-subdiv">
                            {loading ? (
                                <Skeleton animation="wave" className="skleton-loading-screen" width="100%" height={20} />
                            ) : (
                                <span>{RemoveDecimalUSANumberFormat(availsData?.avails_metrics?.total_30s)}</span>
                            )}

                        </div>
                    </div>
                    <div className="platform-detail-header-details-div">Max floor rate
                        <div className="platform-detail-header-details-subdiv">
                            {loading ? (
                                <Skeleton animation="wave" className="skleton-loading-screen" width="100%" height={20} />
                            ) : (
                                <span>${availsData?.avails_metrics?.max_floor_price}</span>
                            )}


                        </div>
                    </div>
                    <div className="platform-detail-header-details-div">Max clearing price
                        <div className="platform-detail-header-details-subdiv">
                            {loading ? (
                                <Skeleton animation="wave" className="skleton-loading-screen" width="100%" height={20} />
                            ) : (
                                <span> ${availsData?.avails_metrics?.max_clearing_price}</span>
                            )}


                        </div>
                    </div>
                </Grid>
                <Grid container className="content-view-component avails-tab-view">
                    {loading ? (


                        <Skeleton animation="wave" className="skleton-loading-screen" width="100%" height={20} />


                    ) : (
                    <Tabs
                    className="content-tabs-view-component"
                    value={selectedTab}
                    onChange={handleTabChange}>
                        {AVAILS_TAB_VIEW.map((data, index) => {
                            return(
                                <Tab value={data} label={data} key={index} />
                            )
                        })}
                    </Tabs>
                    )}
                </Grid>
                <TableAvailsReport
                    reportCount = {reportCount}
                    tableHeader={tableHeaderStr}
                    tableContentData={tableData}
                    isLoading = {loading}
                />

            </Grid>


            <Notification
                message={notificationMessage}
                handleClose={handleClose}
                type={notificationType}
                open={showNotification}
            />
        </Grid>
    )
}

export default AvailsSupplyDetails